@import "../../styles/variables";

.switch-container {
  line-height: 22px;
  button.ant-switch {
    background-color: transparent;
    border: 1px solid @menu-item;
    margin: 0;
    &:after {
      background-color: @menu-item;
      box-shadow: none;
    }
    &.ant-switch-checked {
      border: 1px solid @green-outline-text;
      &:after {
        background-color: @green-outline-text;
      }
    }
  }
}

@primary-color: #3E82F1;