.ant-modal-wrap {
  .batch-modal {
    max-width: 440px;
    .ant-modal-content {
      border-radius: 6px;
      button.ant-modal-close {
        top: 15px;
      }
      .ant-modal-header, .ant-modal-body, .ant-modal-footer {
        padding-left: 27px;
        padding-right: 27px;
      }
      .ant-modal-header {
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: none;
        .ant-modal-title {
          font-size: 18px;
          font-weight: 600;
          text-align: center;
        }
      }
      .ant-modal-body {
        padding-top: 0;
        padding-bottom: 60px;
      }
      .ant-modal-footer {
        padding-top: 0;
        padding-bottom: 30px;
        border-top: none;
        .footer-buttons-container {
          display: flex;
          button.solid-blue {
            margin-left: auto;
          }
          button.blue-outline, button.solid-blue {
            width: 122px;
            justify-content: center;
          }
        }
      }
    }
  }
}

@primary-color: #3E82F1;