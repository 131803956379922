@import "./variables";

.modal-container {
  max-width: 414px;
  background-color: @card-background;
  box-shadow: 4px 6px 16px rgba(160, 166, 178, 0.8);
  border-radius: 6px;
  padding-bottom: 0;
  .ant-modal-title {
    color: @text;
    margin: 0 50px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  .ant-modal-header {
    border-bottom: none;
    padding: 30px 24px 25px 24px;
  }
  .story-list-modal-content-paragraph {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: @text;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .ant-modal-body {
    padding: 0 20px 10px 20px;
  }
  .ant-modal-footer {
    border-top: none;
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
  }
  .modal-edit-button {
    margin-left: auto;
  }
  .label {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: @text;
  }
  .ant-form-item {
    margin-top: 8px;
    margin-right: 0;
    display: block;
    min-height: 100px;
    .ant-form-item-control-wrapper {
      width: 100%;
      textarea {
        min-height: 100px;
      }
    }
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.4);
}