@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.brand-cards-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  .brand-card-filters {
    display: flex;
    flex-wrap: wrap;
    .ant-form-item {
      height: 50px;
      margin-right: 20px;
    }
    .brand-cards-filters-company-select {
      min-width: 140px;
    }
    .ant-select-selection__rendered {
      height: 50px;
      line-height: 50px;
    }
    .ant-select {
      box-shadow: 4px 4px 8px @select-shadow;
      border-radius: 6px;
      color: @menu-item-focused;
    }
    .ant-select-selection {
      outline: none;
      border: none;
      &:hover {
        background-color: @select-hover;
      }
      &:focus, &:active {
        box-shadow: none;
      }
    }
    .ant-select-open .ant-select-selection, .ant-select-focused .ant-select-selection {
      box-shadow: none;
    }
    .ant-select-selection--multiple {
      background-color: @header-background;
      border: none;
      outline: none;
      cursor: pointer;
      color: transparent;
      &:focus, &:active {
        box-shadow: none;
      }
      .ant-select-arrow {
        top: 24px;
      }
    }
    .ant-select-selection__choice {
      height: 44px !important;
      line-height: 44px !important;
    }
    .ant-select-arrow {
      svg {
        .select-arrow();
      }
    }
  }
}

@primary-color: #3E82F1;