@import "mixins";
@import "variables";

* {
  user-select: none;
}
body, #root {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;

  .login-page-container, .reset-password-page-container, .confirm-reset-password-page-container,
  .create-story-container {
    a.back-to-login-link {
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      color: @text;
      font-weight: 500;
      line-height: 20px;
      .anticon.anticon-arrow-left {
        font-size: 12px;
        margin-right: 5px;
        vertical-align: baseline;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .ant-form-item-label {
    line-height: 16px;
    label {
      font-size: 13px;
      color: @text;
    }
  }
  .login-page-wrapper, .reset-password-page-wrapper, .confirm-reset-password-page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .linear-gradient(58.09deg, @auth-start-color-gradient, 7.59%, @auth-end-color-gradient, 101.29%);
    .login-page-container, .reset-password-page-container, .confirm-reset-password-page-container {
      height: 100%;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      input.ant-input {
        height: 41px;
        border-radius: 6px;
        font-size: 15px;
        font-weight: 500;
      }
      .header-container {
        h1 {
          color: @auth-header;
          font-size: 46px;
          margin-bottom: 15px;
        }
      }
      a.back-to-login-link {
        font-size: 16px;
        color: @text;
        font-weight: 500;
        line-height: 20px;
        .anticon.anticon-arrow-left {
          font-size: 12px;
          margin-right: 5px;
          vertical-align: baseline;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .reset-password-form-title {
        text-align: center;
        h2 {
          margin: 45px 0 40px;
        }
      }
      .social-networks-section, .reset-password-form-title {
        h2 {
          font-size: 20px;
          font-weight: 600;
          color: @text;
        }
      }
      .login-form, .reset-password-form, .confirm-reset-password-form {
        width: 414px;
        background-color: @form-background;
        border-radius: 10px;
        .media-phone({width: 100%});
        button.solid-blue {
          display: flex;
          width: 100%;
          justify-content: center;
        }
      }
      .reset-password-form, .confirm-reset-password-form {
        padding: 15px 37px 50px;
      }
    }
    .footer-container {
      color: @footer-text;
      max-width: @layout-width;
      width: 100%;
      margin: 0 auto;
    }
  }
  .create-story-container {
    .story-information-container, .assignments-container {
      .ant-form-item-control {
        span.ant-form-item-children {
          input.ant-input {
            height: 50px;
          }
        }
      }
    }
    .platforms-container {
      .platforms-form {
        .ant-form-item-control {
          line-height: 0;
          span.ant-form-item-children {
            input.ant-calendar-picker-input {
              &.ant-input {
                height: 50px;
              }
            }
          }
          span.ant-time-picker {
            input.ant-time-picker-input {
              height: 50px;
            }
          }
        }
      }
    }
  }
  button.ant-btn {
    height: 44px;
  }
  .login-page-container, .brand-card-list-wrapper {
    .ant-spin {
      span.ant-spin-dot {
        width: 100px;
        height: 100px;
        i.ant-spin-dot-item {
          width: 50px;
          height: 50px;
          background-color: @spinner;
        }
      }
    }
  }
}

.align-right-lg {
  .media-desktop({
    text-align: right;
  })
}

.has-error .ant-form-explain {
  margin-top: 5px;
}

.page-title {
  margin-top: 0 !important;
  margin-bottom: 11px !important;
}

.simple-message-card {
  .ant-card-head-title {
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 16px !important;
    color: @text;
  }
}

.bold {
  font-weight: 600;
}