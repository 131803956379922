.filters-and-new-batch-button-section {
  display: flex;
  .new-batch-button {
    margin-left: auto;
    height: 100%;
    @media (max-width: 430px) {
      padding: 0 14px;
    }
  }
}
.ant-table-wrapper {
  margin-top: 20px;
}

@primary-color: #3E82F1;