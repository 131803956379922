.brand-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #b3b9c6;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
}