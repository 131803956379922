@import "../../../styles/variables";
@import "../../../styles/mixins";

.batch-payments-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: 560px) {
    width: 195px;
  }
  .batch-payments-filters {
    display: flex;
    flex-wrap: wrap;
    .ant-form-item {
      margin: 0 20px 10px 0;
      height: 50px;
      span.custom-filter-date-picker {
        span.ant-calendar-picker-input {
          @media (max-width: 430px) {
            width: 200px;
          }
        }
      }
    }
    .ant-select {
      box-shadow: 4px 4px 8px @select-shadow;
      border-radius: 6px;
      color: @menu-item-focused;
    }
    .ant-select-lg {
      .ant-select-selection--single, .ant-select-selection__rendered {
        height: 50px;
        line-height: 50px;
      }
    }
    .ant-select-selection {
      background-color: @header-background;
      border: none;
      outline: none;
      &:focus, &:active {
        box-shadow: none;
      }
      &:hover {
        background-color: @select-hover;
      }
    }
    .ant-select-focused {
      .ant-select-selection--single {
        background-color: @select-hover;
      }
    }
    .batch-payments-status-select {
      min-width: 97px;
    }
  }
  // select arrow
  .ant-select-arrow svg{
    .select-arrow();
  }

  .ant-select-selection--multiple .ant-select-arrow {
    right: 15px;
    top: 24px;
  }

  .ant-select-open .ant-select-arrow svg {
    fill: @menu-item-focused;
  }
}

@primary-color: #3E82F1;