@import "../../../styles/mixins";
@import "../../../styles/variables";

// common

.filters-spinner {
  text-align: center;
}

.story-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 25px;
  .story-filters {
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;
    @media (max-width: 445px) {
      width: 180px;
    }
  }
  a.story-list-add-button {
    height: 100%;
    min-width: 161px;
  }
  .ant-form-item {
    margin: 0 20px 10px 0;
    height: 50px;
  }
  // reset filters button
  .reset-wrapper {
    cursor: pointer;
    margin: 0 20px 10px 0;
    height: 50px;
    display: flex;
    align-items: center;
    .reset-text {
      padding-left: 7px;
      color: @placeholder;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// multi-select
.story-filters-filter-placeholder {
  color: @menu-item-focused;
}

.ant-select-selected-icon {
  display: none !important;
}

.story-filters-filter-placeholder-icon {
  padding-left: 8px;
}

.story-filters .ant-select-selection--multiple {
  background-color: @header-background;
  border: none;
  outline: none;
  cursor: pointer;
  color: transparent; // to remove blinking line from the input field
  &:focus, &:active {
    box-shadow: none;
  }
}

.story-filters .ant-select-selection__choice {
  height: 44px !important;
  line-height: 44px !important;
}

.ant-select-selection--multiple ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.ant-select-dropdown-menu-item {
  padding-left: 25px;
}

// company select

.story-filters-company-select {
  min-width: 117px;
}

// brand select

.story-filters-brand-select {
  min-width: 110px;
}

// platform select

.story-filters-platform-select {
  min-width: 112px;
}

// content_type select

.story-filters-content_type-select {
  min-width: 152px;
}

// status select

.story-filters-status-select {
  min-width: 97px;
}

// linked_stories select
.story-filters-linked_stories-select {
  min-width: 135px;
}


// select
.story-filters .ant-select {
  box-shadow: 4px 4px 8px @select-shadow;
  border-radius: 6px;
  color: @menu-item-focused;
}

.story-filters .ant-select-lg {
  font-size: 13px; margin-bottom: 10px }

.story-filters .ant-select-lg .ant-select-selection--single,
.story-filters .ant-select-lg .ant-select-selection__rendered,
.story-filters .ant-select-selection-selected-value {
  height: 50px;
  line-height: 50px;
}

.story-filters .ant-select-selection {
  background-color: @header-background;
  border: none;
  outline: none;
  &:focus, &:active {
    box-shadow: none;
  }
}

.story-filters .ant-select-focused .ant-select-selection--single {
  background-color: @select-hover;
}

.story-filters .ant-select-open .ant-select-selection,
.story-filters .ant-select-focused .ant-select-selection {
  box-shadow: none;
  background-color: @select-hover;
}

.story-filters .ant-select-arrow {
  right: 15px;
}

.story-filters .ant-select-selection {
  &:hover {
    background-color: @select-hover;
  }
}

// tree select

.story-filters .tree-select {
  min-width: 218px;
  .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
    font-weight: 500;
    color: @menu-item-focused;
    font-size: 13px;
  }
}
// tree select static arrow
.tree-select .ant-select-selection--multiple {
  background-image: url("../../../images/icons/tree-select-arrow.svg");
  background-repeat: no-repeat;
  background-position: 93% center;
  background-size: 17px 17px;
}

.story-filters .filters-tree-select-dropdown {
  max-height: 281px !important;
  min-width: 276px !important;
}

.story-filters .ant-select-selection-selected-value {
  padding-right: 25px;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: @select-hover;
}


.ant-select-tree li .ant-select-tree-node-content-wrapper {
  &:hover {
    background: none;
  }
}

.ant-select-tree li.ant-select-tree-treenode-switcher-close {
  &:hover {
    background-color: @select-hover;
  }
}

// tree select subcategory arrow

.ant-select-tree-switcher_open {
  float: right;
}

.ant-select-tree-treenode-switcher-close {
  display: flex;
  flex-direction: row-reverse;
}

.ant-select-tree-switcher {
  svg {
    width: 20px;
    height: 20px;
    fill: #D2D5DC;
  }
  top: 4px;
}

.ant-select-tree-switcher_open {
  svg {
    fill: @menu-item-focused;
  }
}


.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transform: none;
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  transform: rotate(180deg);
}

// arrows

.story-filters .ant-select-arrow svg{
  .select-arrow();
}

.story-filters .ant-select-selection--multiple .ant-select-arrow {
  right: 15px;
  top: 24px;
}

.story-filters .ant-select-open .ant-select-arrow svg {
  fill: @menu-item-focused;
}

// search
.common-search {
  width: 321px;
}

@primary-color: #3E82F1;