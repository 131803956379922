@import "../../../styles/variables";

.tasks-list {
  span.custom-filter-date-picker.ant-calendar-picker {
    .ant-calendar-picker-input {
      width: 300px;
    }
  }
  .ant-select-search__field__wrap {
    padding-right: 15px;
    padding-top: 7px;
    color: @text;
  }
}
.task-filters-type-select {
  min-width: 120px;
}

.task-filters-story-status-select {
  min-width: 130px;
}

.task-filters-user-select {
  min-width: 112px;
}

.task-filters-content_type-select {
  min-width: 152px;
}

.task-filters-status-select {
  min-width: 125px;
}

.common-search {
  width: 321px;
}

.task-filters-creator-select {
  min-width: 130px;
}

@primary-color: #3E82F1;