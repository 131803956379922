@import "../../styles/variables";

.login-page-wrapper {
  .login-page-container {
    .login-form {
      padding: 50px 37px;
      .ant-form-item {
        margin: 0 0 25px 0;
        height: 100%;
        &:last-of-type {
          margin-bottom: 8px;
        }
      }
      a.forgot-password-link {
        margin-top: 8px;
        color: @text;
        font-size: 13px;
        &:hover {
          text-decoration: underline;
        }
      }
      button.solid-blue {
        margin-top: 30px;
      }
    }
    .sign-up-section {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      span.text-section {
        font-size: 15px;
        font-weight: 500;
        color: @solid-white-text;
        margin-right: 16px;
      }
      button.solid-white {
        height: 40px;
        min-width: 110px;
        font-weight: 500;
      }
    }
  }
}

@primary-color: #3E82F1;