@import "../../styles/variables";

.ant-upload {
  .uploader-container {
    display: flex;
    align-items: center;
    background-color: @content-background;
    padding: 7px 15px 13px 14px;
    border-radius: 6px;
    span.uploader-placeholder {
      margin-right: 17px;
      font: 15px "Montserrat", sans-serif;
      font-weight: 500;
      color: @text;
    }
  }
}
.ant-upload-list-item {
  &:hover {
    .ant-upload-list-item-info {
      background-color: transparent;
    }
  }
}

@primary-color: #3E82F1;