.user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-common {
  font-weight: bold;
  font-style: normal;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  &:disabled {
    cursor: not-allowed;
  }
}

.solid-blue-disabled {
  background-color: @solid-blue-disabled-background;
  color: @solid-blue-disabled-text;
  path {
    fill: @blue-outline-disabled-text;
  }
}

.blue-outline-disabled {
  border: 1px solid @blue-outline-disabled-text;
  background-color: @blue-outline-disabled-background;
  color: @blue-outline-disabled-text;
  path {
    fill: @blue-outline-disabled-text;
  }
}

.green-outline-disabled {
  border: 1px solid @green-outline-disabled-text;
  background-color: @green-outline-disabled-background;
  color: @green-outline-disabled-text;
  .button-icon path {
    fill: @green-outline-disabled-text;
  }
}

.black-outline-disabled() {
  border: 1px solid @black-outline-disabled-text;
  background-color: @black-outline-disabled-background;
  color: @black-outline-disabled-text;
  .button-icon path {
    fill: @black-outline-disabled-text;
  }
}

.red-outline-disabled() {
  border: 1px solid @red-outline-disabled-text;
  background-color: @red-outline-disabled-background;
  color: @red-outline-disabled-text;
  .button-icon path {
    fill: @red-outline-disabled-text;
  }
}

.grey-outline-disabled() {
  border: 1px solid @grey-outline-disabled-text;
  background-color: @grey-outline-disabled-background;
  color: @grey-outline-disabled-text;
  .button-icon path {
    fill: @grey-outline-disabled-text;
  }
}

.select-arrow {
  width: 17px;
  height: 17px;
  fill: @disabled;
}

.search-input-common {
  &:hover {
    input {
      outline:none;
      border-color: #d9d9d9 !important;
      background-color: @select-hover;
    }
    outline:none;
    border-color: #d9d9d9;
    background-color: @select-hover;
  }
  input {
    padding-left: 35px !important;
    &::placeholder {
      font-weight: 500;
      color: @placeholder;
    }
    &:focus, &:hover {
      outline:none;
      border-color: #d9d9d9;
      background-color: @select-hover;
      box-shadow: none;
    }
  }
  .ant-input-prefix {
    z-index: 1;
  }
}


.linear-gradient(@direction, @start-color, @start-corner, @end-color, @end-corner) {
  background: -webkit-gradient(@direction, @start-color @start-corner, @end-color @end-corner);
  background: -moz-gradient(@direction, @start-color @start-corner, @end-color @end-corner);
  background: -ms-gradient(@direction, @start-color @start-corner, @end-color @end-corner);
  background: linear-gradient(@direction, @start-color @start-corner, @end-color @end-corner);
}

// ===========================================
// Breakpoints
// ===========================================
@screen-phone: 767px;
@screen-tablet: 922px;
@screen-desktop: 1200px;

// ===========================================
// Responsive Mixins
// ===========================================
.media-phone(@rules) {
  @media screen and (max-width: @screen-phone) {
    @rules();
  }
}

.media-tablet(@rules) {
  @media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) {
    @rules();
  }
}

.media-desktop(@rules) {
  @media screen and (min-width: @screen-desktop) {
    @rules();
  }
}

.media-custom-max(@width, @rules) {
  @media screen and (max-width: @width) {
    @rules();
  }
}

.media-custom-min(@width, @rules) {
  @media screen and (min-width: @width) {
    @rules();
  }
}

// Greater Than
.media-gt-phone(@rules) {
  @media (min-width: (@screen-phone + 1px)) {
    @rules();
  }
}

.media-gt-tablet(@rules) {
  @media (min-width: (@screen-tablet + 1px)) {
    @rules();
  }
}
