@import "../../styles/variables";
@import "../../styles/mixins";

.footer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  padding: 60px 0 20px 0;
  color: @menu-item;
  .footer-copyrights, .footer-container-item {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .footer-copyrights {
    margin-right: auto;
  }
  .footer-container-item {
    cursor: pointer;
    padding-left: 50px;
    &:hover {
      color: @menu-item-focused;
    }
    &:first-child {
      margin-right: auto;
      padding-left: 0;
    }
  }
  @media(max-width: 1315px) {
    padding-left: 7px;
    padding-right: 7px;
  }
}

@primary-color: #3E82F1;