@import "../../styles/variables";
@import "../../styles/mixins";

.tasks-list {
  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: @text;
  }
  .description {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-top: 8px;
    color: @menu-item-focused;
    .brand-icon {
      display: inline-block;
      margin-right: 10px;
    }
    &.task-brief {
      word-break: break-word;
      .media-desktop({padding-right: 75px;});
    }
  }

  .status-dot {
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    margin-right: 6px;
    display: inline-block;
  }
  .status {
    font-size: 13px;
    margin-top: 20px;
  }
  .assignment-type {
    background: #f6f7fb;
    border-radius: 28px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 14px;
    display: flex;
    align-items: center;
    width: max-content;
    span {
      display: inline-block;
    }
  }
  .create-commission-button {
    width: 152px;
    padding: 0 9px;
    text-align: center;
  }
  .commission-button {
    width: 166px;
    padding: 0 11px;
    text-align: center;
    .button-text-container {
      text-align: center;
      .status-container {
        .status-name {
          font-size: 11px;
        }
      }
    }
  }
  .tasks-list-actions-wrapper {
    display: flex;
    flex-direction: column;
    .commission-button,
    .create-commission-button {
      @media (max-width: 991px) {
        margin: 0 10px 10px 0;
        vertical-align: top;
      }
    }
    .green-outline {
      max-width: 137px;
    }
  }

  .assignment-type {
    background: #f6f7fb;
    border-radius: 28px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 14px;
    display: flex;
    align-items: center;
    width: max-content;
    span {
      display: inline-block;
    }
  }
  @media (max-width: 991px) {
    text-align: left;
    margin-top: 16px;
  }
  .assignment-button {
    margin: 0 0 16px 0;
    @media (max-width: 991px) {
      margin: 0 10px 10px 0;
    }
  }
  .manage-content-button {
    display: inline-flex;
    min-width: 146px;
    height: 20px;
  }
  .editable-status {
    margin-top: 10px;
    display: inline-flex;
  }
  .editable-status-story {
    display: flex;
    flex-flow:row wrap;
    margin-top: 15px;
  }
  .content-type-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100px;
    .category-name {
      margin-top: 10px;
    }
  }
}
.editable-status-select {
  width: 100%;
}

@primary-color: #3E82F1;