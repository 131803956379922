@import "./variables";

.ant-pagination-item, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  display: none !important;
}

.ant-pagination-options {
  float: left;
}

.ant-table-pagination {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: @menu-item-focused;
}

.ant-pagination.ant-table-pagination {
  margin-right: 30px;
}

li.ant-pagination-next,
li.ant-pagination-prev {
  color: @menu-item-focused;
}

.pagination-arrow-back svg {
  transform: rotate(180deg);
}

.pagination-arrow-next,
.pagination-arrow-back {
  font-family: "Montserrat", sans-serif;
  display: inline-flex;
  align-items: center;
  span {
    padding: 0 5px;
    display: inline-flex;
    align-items: center;
  }
}

li.ant-pagination-disabled {
  color: @disabled;
  .pagination-arrow-next path,
  .pagination-arrow-back path {
    stroke: @disabled;
  }
}

.ant-pagination-total-text {
  color: @menu-item;
}

.ant-pagination-options {
  .ant-pagination-options-size-changer {
    font-size: 13px;
  }
  .ant-select-dropdown-menu-item {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
  }
}

.ant-pagination-options-size-changer .ant-select-selection-selected-value {
  font-size: 13px;
  color: @menu-item-focused;
}

.ant-pagination-options-size-changer .ant-select-selection--single {
  background-color: @header-background;
  border: none;
  outline: none;
  &:focus,
  &:active {
    box-shadow: none;
  }
}

.ant-pagination-options .ant-select-focused .ant-select-selection--single {
  background-color: @select-hover;
}