@import "../../styles/variables";

.back-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: @menu-item-focused;
  margin-bottom: 20px;
  .anticon {
    margin-right: 5px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.page-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: @menu-item-focused;
}

.story-panel {
  .profile-details-row {
    .ant-col {
      margin-bottom: 25px;
    }
    .ant-col{
      .label-description-wrapper:not(:first-child) {
        margin-top: 30px;
      }
    }
    .brand-wrapper {
      margin-bottom: 15px;
    }
  }

  .change-password-button {
    padding: 0 13px;
  }

  .switch-wrapper {
    margin-top: 35px;
    display: inline-flex;
    align-items: center;
    .notify-switch {
      margin-right: 10px;
    }
  }
}

.change-password-form {
  margin-top: 35px;
  input {
    width: 300px;
  }
}

.change-password-form {
  .label-description-wrapper {
    margin-top: 10px !important;
    .ant-form-item {
      display: unset;
      height: unset;
    }
  }
  .ant-form-explain {
    max-width: 400px;
  }
}

.small-field {
  max-width: 250px;
}


@primary-color: #3E82F1;