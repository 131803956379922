@import "./variables";
@import "./mixins";

// search fields
.search-input {
  height: 50px;
  box-shadow: 4px 4px 8px rgba(216, 221, 232, 0.8);
  &:hover {
    input {
      outline:none;
      border-color: #d9d9d9 !important;
      background-color: @select-hover;
    }
    outline:none;
    border-color: #d9d9d9;
    background-color: @select-hover;
  }
  .ant-input-prefix svg{
    width: 19px;
    height: 19px;
  }
  input {
    padding-left: 35px !important;
    &::placeholder {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: @placeholder;
    }
    &:focus, &:hover {
      outline:none;
      border-color: #d9d9d9;
      background-color: @select-hover;
      box-shadow: none;
    }
  }
  .ant-input-prefix {
    z-index: 1;
  }
  // input placeholder alignment in firefox 19+
  input[type="text"]::-moz-placeholder {
    line-height: 35px !important;
  }
}

@primary-color: #3E82F1;