@import "../../styles/variables";
@import "../../styles/mixins";

span.custom-filter-date-picker {
  cursor: pointer !important;
  input {
    cursor: pointer !important;
  }
  &.ant-calendar-picker {
    &:focus {
      .ant-calendar-picker-input:not(.ant-input-disabled) {
        box-shadow: 4px 4px 8px @shadow;
      }
    }
    span.ant-calendar-picker-input {
      display: flex;
      align-items: baseline;
      height: 50px;
      width: 240px;
      border: none;
      box-shadow: 4px 4px 8px @shadow;
      &:hover {
        background-color: @select-hover;
      }
      i.ant-calendar-picker-clear {
        right: 12px;
      }
      i.anticon.anticon-calendar {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 7px 0 7px;
        border-color: @text transparent transparent transparent;
        position: relative;
        top: -2px;
        left: -6px;
        svg {
          display: none;
        }
      }
      span.ant-calendar-range-picker-separator {
        font-size: 13px;
        color: @text;
        font-weight: 500;
      }
      input.ant-calendar-range-picker-input {
        font-size: 13px;
        color: @text;
        font-weight: 500;
        &::placeholder {
          font-size: 13px;
          color: @text;
          font-weight: 500;
        }
      }
    }
  }
}
.ant-calendar.ant-calendar-range {
  .ant-calendar-year-panel, .ant-calendar-month-panel, .ant-calendar-decade-panel {
    top: 50px;
  }

  .ant-calendar-in-range-cell {
    &::before {
      background-color: @hover;
    }
  }

  .ant-calendar-panel {
    font-size: 13px;

    .ant-calendar-date {
      &:hover {
        background-color: @hover;
      }
    }

    .ant-calendar-year-panel-tbody {
      a.ant-calendar-year-panel-year {
        &:hover {
          background-color: @hover;
        }
      }
    }

    .ant-calendar-month-panel-tbody {
      a.ant-calendar-month-panel-month {
        &:hover {
          background-color: @hover;
        }

        a.ant-calendar-month-panel-month {
          &:hover {
            background-color: @hover;
          }
        }
      }

      span.ant-calendar-range-middle {
        left: 35%;
      }

      span.ant-calendar-range-middle, .ant-calendar-input-wrap {
        height: 50px;
        display: flex;
        align-items: center;
      }

      .ant-calendar-range-right {
        .ant-calendar-date-input-wrap {
          margin-left: -184px;
        }
      }

      .ant-calendar-input-wrap {
        .ant-calendar-input {
          width: 85px;
        }
      }
    }
  }
}

.ant-calendar-picker-input {
  .anticon-caret-down  svg{
    .select-arrow();
  }
}

.ant-calendar-picker-icon, .ant-calendar-picker-clear {
  right: 15px !important;
}

@primary-color: #3E82F1;