@import "../../styles/variables";

.action-log-wrapper {
  @media(min-width: 1200px) {
    .action-date-column {
      min-width: 144px;
    }
    .entity-id-column {
      min-width: 108px;
    }
  }
  .ant-pagination-options-size-changer .ant-select-selection--single {
    display: flex;
    align-items: center;
  }
  .ant-pagination-options .ant-select-selection__rendered{
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
  // sorting arrows alignment
  .ant-table-thead > tr > th .ant-table-header-column {
    vertical-align: top;
  }
  .ant-table-column-title {
    vertical-align: top;
  }
  @media(max-width: 1200px) {
    .ant-table-thead > tr > th .ant-table-header-column {
      vertical-align: middle;
    }
    .ant-table-column-title {
      vertical-align: middle;
    }
  }
}

.ant-tooltip-inner {
  background: @tooltip-background;
  box-shadow: 4px 4px 8px rgba(216, 221, 232, 0.8);
  border-radius: 6px;
}

@primary-color: #3E82F1;