@import "./variables";

.hasValue {
  background-color: @select-hover !important;
  .ant-select-selection, .ant-calendar-picker-input, .ant-time-picker-input {
    background-color: @select-hover !important;
  }
}

// Chrome is auto-filling input fields and changes the background color. Use this to fix it:
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px @select-hover inset !important;
}