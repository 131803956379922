.anticon {
  &:hover {
    cursor: pointer;
  }
}
.assignment-icon {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  &.paid_media {
    background-image: url("../images/icons/assignment-paid-media.svg");
  }
  &.text {
    background-image: url("../images/icons/assignment-text.svg");
  }
  &.audio {
    background-image: url("../images/icons/assignment-audio.svg");
  }
  &.image {
    background-image: url("../images/icons/assignment-image.svg");
  }
  &.services {
    background-image: url("../images/icons/assignment-service.svg");
  }
  &.media_via_subscription {
    background-image: url("../images/icons/assignment-subscription.svg");
  }
  &.free_media {
    background-image: url("../images/icons/assignment-free-media.svg");
  }
  &.video {
    background-image: url("../images/icons/assignment-video.svg");
  }
}
