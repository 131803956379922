@import "../../styles/variables";

.batch-information {
  .payment-details-row-title {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: @text;
  }
  .payment-details-row-description {
    margin-top: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: @text;
  }
  .payment-details-row:not(:first-child) {
    margin-top: 30px;
  }
}

.payment-details-commissions-row {
  .description {
    .task-type-name {
      @media(max-width: 1200px) and (min-width: 991px) {
        max-width: 170px;
        word-break: break-word;
      }
    }
  }
}
@primary-color: #3E82F1;