@import "../../styles/variables";

.more-link {
  font-size: 13px;
  line-height: 16px;
  color: @blue-outline-text;
  cursor: pointer;
}

.read-more-text {
  word-break: break-word;
}
@primary-color: #3E82F1;