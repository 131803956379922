@import "../../styles/variables";
@import "../../styles/mixins";

.story-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: @text;
  margin-bottom: 11px;
}

.story-panel {
  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: @text;
  }
  .description {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-top: 8px;
    white-space: pre-wrap;
    color: @menu-item-focused;
    .brand-icon {
      display: inline-block;
      margin-right: 10px;
    }
    &.task-brief {
      word-break: break-word;
      .media-desktop({padding-right: 75px;});
    }
  }

  .status-dot {
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    margin-right: 6px;
    display: inline-block;
  }
  .status {
    font-size: 13px;
    margin-top: 20px;
  }
  .assignment-type {
    background: #f6f7fb;
    border-radius: 28px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 14px;
    display: flex;
    align-items: center;
    width: max-content;
    span {
      display: inline-block;
    }
  }
  .task-creator {
    margin-top: 25px;
  }
  .create-commission-button {
    width: 152px;
    padding: 0 9px;
    text-align: center;
  }
  .commission-button {
    width: 166px;
    padding: 0 11px;
    text-align: center;
    .button-text-container {
      text-align: center;
      .status-container {
        .status-name {
          font-size: 11px;
        }
      }
    }
  }
  .commission-button,
  .create-commission-button {
    @media (max-width: 991px) {
      margin: 0 10px 10px 0;
      vertical-align: top;
    }
  }
  // attachments
  .story-attachments {
    word-break: break-word;
    &:hover {
      cursor: pointer;
      text-shadow: 0 0 0.01px black;
    }
  }
  // assignments and platforms
  .assignments-row,
  .platforms-row {
    padding-bottom: 20px !important;
    &:first-child {
      padding-top: 0 !important;
    }
    .ant-col {
      margin-bottom: 0 !important;
    }
  }

  .task_start_date {
    margin-bottom: 25px;
  }
}

.buttons-container {
  .delete-button {
    margin-left: 0;
    margin-right: auto;
  }
}

.assignments-buttons-wrapper {
  text-align: right;
  @media (max-width: 991px) {
    text-align: left;
    margin-top: 16px;
  }
  .assignment-button {
    margin: 0 0 16px 0;
    @media (max-width: 991px) {
      margin: 0 10px 10px 0;
    }
  }
  .manage-content-button {
    display: inline-flex;
    min-width: 146px;
    height: 20px;
  }
}

// chat

.chat {
  .ant-card-body {
    transition: max-height 10s ease-out;
    overflow: hidden;
    height: auto;
    max-height: 3500px; // have to hard-code a value for chat smooth appearance,
    // so high value to show the whole table in case of 30 or more messages/page
  }
  .ant-card-head-title {
    width: 100%;
    cursor: pointer;
  }
  .spin-centered {
    display: block;
    text-align: center;
    border-radius: 4px;
    padding: 30px 50px;
    margin: 20px 0;
  }
}

// chat-link
.links-container {
  display: flex;
  flex-direction: row;
}
.chat-link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  margin-left: auto;
  .chat-link {
    color: @text;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    border-bottom: 1px dotted;
    cursor: pointer;
  }
  .message-icon {
    margin-right: 12px;
    cursor: initial;
    svg {
      cursor: initial;
    }
    path {
      stroke: @text;
    }
  }
}

@primary-color: #3E82F1;