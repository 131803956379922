img {
  max-width: 100%;
  height: auto;
}

* {
  margin: 0;
  padding: 0;
  text-indent: 0;
  font-family: 'Montserrat' !important;
}

/* edited */

#l10 > li > *:first-child:before {
  content: "●";
  margin-right: 4px;
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l21 > li > *:first-child:before {
  content: "○";
  margin-right: 4px;
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l22 > li > *:first-child:before {
  content: "○";
  margin-right: 4px;
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

/* edited */

.s1 {
  color: #3d81f1;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 21pt;
}

.s3 {
  color: #3d81f1;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 21pt;
}

h2 {
  color: #8975ff;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 21pt;
}

.s4 {
  color: #8975ff;
  font-family: Calibri, sans-serif;
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
  font-size: 18pt;
}

.s5 {
  color: #3d81f1;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 22pt;
}

.s6 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 21pt;
}

h4 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 13pt;
}

.s7 {
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 13pt;
}

.s8 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}

.s9 {
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

.p {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin: 0pt;
}

.s10 {
  color: #fff;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 14pt;
}

.s11 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}

.s12 {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

.s13 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
  font-size: 10pt;
}

.help h1 {
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 22pt;
}

.help > h3 {
  color: #3d81f1;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 16pt;
}

li {
  display: block;
}

#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}

#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

li {
  display: block;
}

#l2 {
  padding-left: 0pt;
  counter-reset: d1 1;
}

#l2 > li > *:first-child:before {
  counter-increment: d1;
  content: counter(d1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l2 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}

li {
  display: block;
}

#l3 {
  padding-left: 0pt;
  counter-reset: e1 1;
}

#l3 > li > *:first-child:before {
  counter-increment: e1;
  content: counter(e1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l3 > li:first-child > *:first-child:before {
  counter-increment: e1 0;
}

li {
  display: block;
}

#l4 {
  padding-left: 0pt;
  counter-reset: f1 1;
}

#l4 > li > *:first-child:before {
  counter-increment: f1;
  content: counter(f1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l4 > li:first-child > *:first-child:before {
  counter-increment: f1 0;
}

#l5 {
  padding-left: 0pt;
  counter-reset: f2 1;
}

#l5 > li > *:first-child:before {
  counter-increment: f2;
  content: counter(f2, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

.help td {
  padding: 0px 7px 7px 7px;
}

#l5 > li:first-child > *:first-child:before {
  counter-increment: f2 0;
}

#l6 {
  padding-left: 0pt;
  counter-reset: f3 1;
}

#l6 > li > *:first-child:before {
  counter-increment: f3;
  content: counter(f3, lower-roman) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l6 > li:first-child > *:first-child:before {
  counter-increment: f3 0;
}

li {
  display: block;
}

#l7 {
  padding-left: 0pt;
  counter-reset: g1 1;
}

#l7 > li > *:first-child:before {
  counter-increment: g1;
  content: counter(g1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l7 > li:first-child > *:first-child:before {
  counter-increment: g1 0;
}

#l8 {
  padding-left: 0pt;
  counter-reset: g2 1;
}

#l8 > li > *:first-child:before {
  counter-increment: g2;
  content: counter(g2, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l8 > li:first-child > *:first-child:before {
  counter-increment: g2 0;
}

.help li {
  display: block;
}

#l9 {
  padding-left: 0pt;
  counter-reset: h1 1;
}

#l9 > li > *:first-child:before {
  counter-increment: h1;
  content: counter(h1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l9 > li:first-child > *:first-child:before {
  counter-increment: h1 0;
}

#l10 {
  padding-left: 0pt;
}

#l11 {
  padding-left: 0pt;
  counter-reset: j1 1;
}

#l11 > li > *:first-child:before {
  counter-increment: j1;
  content: counter(j1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l11 > li:first-child > *:first-child:before {
  counter-increment: j1 0;
}

#l12 {
  padding-left: 0pt;
  counter-reset: j2 1;
}

#l12 > li > *:first-child:before {
  counter-increment: j2;
  content: counter(j2, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l12 > li:first-child > *:first-child:before {
  counter-increment: j2 0;
}

#l13 {
  padding-left: 0pt;
  counter-reset: k1 1;
}

#l13 > li > *:first-child:before {
  counter-increment: k1;
  content: counter(k1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l13 > li:first-child > *:first-child:before {
  counter-increment: k1 0;
}

#l14 {
  padding-left: 0pt;
  counter-reset: k2 1;
}

#l14 > li > *:first-child:before {
  counter-increment: k2;
  content: counter(k2, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l14 > li:first-child > *:first-child:before {
  counter-increment: k2 0;
}

#l15 {
  padding-left: 0pt;
  counter-reset: l1 1;
}

#l15 > li > *:first-child:before {
  counter-increment: l1;
  content: counter(l1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l15 > li:first-child > *:first-child:before {
  counter-increment: l1 0;
}

#l16 {
  padding-left: 0pt;
  counter-reset: l2 1;
}

#l16 > li > *:first-child:before {
  counter-increment: l2;
  content: counter(l2, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l16 > li:first-child > *:first-child:before {
  counter-increment: l2 0;
}

#l17 {
  padding-left: 0pt;
  counter-reset: m1 1;
}

#l17 > li > *:first-child:before {
  counter-increment: m1;
  content: counter(m1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l17 > li:first-child > *:first-child:before {
  counter-increment: m1 0;
}

#l18 {
  padding-left: 0pt;
  counter-reset: m2 1;
}

#l18 > li > *:first-child:before {
  counter-increment: m2;
  content: counter(m2, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l18 > li:first-child > *:first-child:before {
  counter-increment: m2 0;
}

#l19 {
  padding-left: 0pt;
  counter-reset: n1 1;
}

#l19 > li > *:first-child:before {
  counter-increment: n1;
  content: counter(n1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l19 > li:first-child > *:first-child:before {
  counter-increment: n1 0;
}

#l20 {
  padding-left: 0pt;
  counter-reset: n2 1;
}

#l20 > li > *:first-child:before {
  counter-increment: n2;
  content: counter(n2, lower-latin) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l20 > li:first-child > *:first-child:before {
  counter-increment: n2 0;
}

#l21 {
  padding-left: 0pt;
}

#l22 {
  padding-left: 0pt;
}

#l23 {
  padding-left: 0pt;
  counter-reset: o1 1;
}

#l23 > li > *:first-child:before {
  counter-increment: o1;
  content: counter(o1, decimal) ". ";
  color: black;
  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

#l23 > li:first-child > *:first-child:before {
  counter-increment: o1 0;
}
.contents p, .contents h4{
  display: flex;
  justify-content: space-between;
}
.main-block {
  margin: 0px 100px 100px 100px;
  display: flex;
  flex-direction: column;
}
.contents a {
  text-decoration: none;
  color: black;
}
.main-block h3 {
  color: #3d81f1;
}
.help {
  font-family: Montserrat,sans-serif;
  font-style: normal;
}
@primary-color: #3E82F1;