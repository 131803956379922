@import "../../styles/variables";

.contributor-summary {
  margin-bottom: 5px;
}
.cost-description-row-view {
  margin-bottom: 30px;
}
.commission-view-buttons-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  button,
  a {
    margin-right: 30px;
  }
  .edit-button {
    margin-left: auto;
    margin-right: 0;
  }
}
.cost-form-row:not(:last-child), .cost-allocation-form:not(:last-child) {
  margin-bottom: 20px;
}
.percentage {
  width: 100%;
  word-break: break-word;
}
.editable-status-select-wrapper {
  .editable-status-select {
    min-width: 175px;
    .ant-select-selection--single {
      height: 32px;
      .ant-select-selection__rendered {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

.bottom-info {
  margin-top: 24px;
}

.costs-title-currency-total {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: @placeholder;
  padding-left: 20px;
}

.file_icon {
  margin-right: 8px;
}

@primary-color: #3E82F1;