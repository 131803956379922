@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.brand-card-list-wrapper {
  .filter-story-creation-option-container {
    > * {
      margin-bottom: 20px;
    }
    display: flex;
    align-items: center;
    a.solid-blue {
      margin-left: auto;
      .media-phone({
        margin-left: initial;
      })
    }
    .media-phone({
      flex-direction: column-reverse;
      align-items: flex-start;
    })
  }
  .brand-cards-list-container {
    display: block;
  }

  .brand-card-list-row:last-of-type {
    flex: 1;
  }
}

.brand-card-list-row {
  .ant-col {
    &:first-of-type {
      a.container-link {
        margin-right: 20px;
        @media (max-width: 991px) {
          margin-right: 0;
        }
      }
    }
  }
}

@primary-color: #3E82F1;