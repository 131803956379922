.ant-card-head {
  .ant-card-head-wrapper {
    flex-direction: row !important;
    align-items: center !important;
  }
}

.title-buttons {
  display: flex;
  a:first-child {
    margin-right: 20px;
  }
}

.blue-outline .button-icon {
  width: 14px;
  height: 14px;
}

.linked-stories-card {
  .ant-card-body {
    padding: 0 0 !important;
  }
  .ant-table-wrapper {
    margin-bottom: 0px;
  }
}

@primary-color: #3E82F1;