@import "../../styles/variables";

.tabs-container {
  .ant-tabs {
    overflow: visible;
    .ant-tabs-bar {
      border-bottom: none;
      &.ant-tabs-top-bar.ant-tabs-card-bar {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: 50px;
        margin-bottom: 0;
      }
      .ant-tabs-nav-container {
        margin-right: auto;
      }
      .ant-tabs-tab {
        background-color: transparent;
        border: none;
        font-size: 24px;
        font-weight: 600;
        color: @tab-header-text;
        padding: 0;
        margin-right: 20px;
        &:last-of-type {
          margin-right: 0;
        }
        &.ant-tabs-tab-active, &:hover {
          color: @text;
        }
      }
    }
  }
}

@primary-color: #3E82F1;