@import "../../styles/variables";
@import "../../styles/mixins";

.page-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: @menu-item-focused;
}

.manage-content {
  color: @menu-item-focused;
   .ant-card-body {
     .ant-col {
       margin-top: 20px;
       margin-bottom: 25px;
       padding: 0 !important;
     }
   }

  .add-text-content-button {
    margin-top: 25px;
    padding: 0 25px;
  }

  .label-description-wrapper {
    .description {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .add-video-link-button {
    margin-left: 20px;
  }

  .add-text-content-form {
    margin-top: 30px;
    .ant-col {
      margin-top: 8px;
      margin-bottom: 0;
      padding: 0 !important;
    }

    .label-description-wrapper {
      margin-top: 0 !important;
      .ant-form-item {
        display: flex;
        height: unset;
        .validation-success-background {
          background-color: @select-hover;
        }
        .add-text-content-form-title-input {
          width: 360px;
          .media-phone({
            width: 300px;
          });
        }
      }
    }

    .ant-form-explain {
      max-width: 400px;
    }

    .add-text-content-form-textarea {
      width: 790px;
      min-height: 160px;
      .media-tablet({
        width: 550px;
      });
      .media-phone({
        max-width: 300px;
      });
    }

    .add-text-content-form-item-button-wrapper,
    .add-description-content-form-item-button-wrapper {
      height:30px;
      margin-top: 24px;
      .ant-col {
        margin: 0;
      }
      .ant-form-item-control {
        line-height: unset;
      }
    }

    .add-text-content-form-item-button-wrapper {
      margin-bottom: 80px;
    }

    .add-text-content-form-submit-button {
      padding: 0 25px;
    }

    .ant-form-item-with-help {
      margin-bottom: 0;
    }
  }
}

.uploaded-text-file {
  display: flex;
  margin-top: 8px;
  padding-bottom: 25px;
  border-bottom: 1px solid @line;
  .uploaded-text-file-name {
    cursor: pointer;
    padding-left: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    &:hover {
      font-weight: 600;
    }
  }
  .uploaded-text-file-delete {
    padding-left: 15px;
    margin-left: auto;
    cursor: pointer;
    &:hover {
      path {
        stroke: @menu-item-focused;
      }
    }
    svg {
      width: 17px;
      height: 17px;
    }

  }
}

.attached-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  padding-bottom: 41px;
  border-bottom: 1px solid @line;
  .uploaded-image-file {
    cursor: pointer;
    width: 132px;
    margin-right: 20px;
    .image-container {
      position: relative;
      width: 100%;
      height: 115px;
      .uploaded-image {
        width: 100%;
        height: 100%;
      }
      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        transition: .5s ease;
        opacity: 0;
        .icon-upload {
          position: absolute;
          left:20%;
          top:40%;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.2);
          }
        }
        .icon-cross {
          transition: all 0.5s;
          position: absolute;
          right:20%;
          top:40%;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .uploaded-image-file-name {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: @menu-item;
      word-break: break-word;
    }
    &:hover {
      .uploaded-image-file-name {
        text-decoration-line: underline;
        color: @menu-item-focused;
      }
      .overlay {
        opacity: 1;
      }

    }

  }
}



@primary-color: #3E82F1;