@import "../../styles/variables";
@import "../../styles/mixins";
// change status
.editable-status .ant-tag {
  min-width: 120px;
  height: 22px;
  margin-top: -6px;
  border-radius: 28px;
  text-align: center;
  cursor: pointer;
}

.editable-status {
  align-items: center;
  .anticon {
    visibility: hidden;
  }
  &:hover {
    .anticon {
      visibility: visible;
    }
  }
  .dot {
    margin-right: 10px;
    .status-dot {
      min-width: 10px;
      min-height: 10px;
      border-radius: 50%;
      margin-right: 10px;
      display: inline-block;
    }

    .status {
      font-size: 13px;
      margin-top: 20px;
    }
  }
  .tag-text {
    font-weight: 600;
  }
}

.hoverable {
  &:hover {
    path {
      fill: #3e3d47;
    }
  }
}

// change status select

.editable-status-select-wrapper .editable-status-select {
  min-width: 120px !important;
}

.editable-status-select-wrapper {
  font-weight: 500;
}

.editable-status-select-wrapper .ant-select-selection {
  background-color: @header-background;
  border: none;
  outline: none;
  &:focus, &:active {
    box-shadow: none;
  }
}

.editable-status-select-wrapper .ant-select {
  box-shadow: 4px 4px 8px rgba(216, 221, 232, 0.8);
  border-radius: 6px;
  color: @menu-item-focused;
}

.editable-status-select-wrapper .ant-select-lg {
  font-size: 13px;
  margin-bottom: 10px
}

.editable-status-select-wrapper .ant-select-lg .ant-select-selection--single,
.editable-status-select-wrapper .ant-select-lg .ant-select-selection__rendered,
.editable-status-select-wrapper .ant-select-selection-selected-value {
  height: 50px;
  line-height: 50px;
}

.editable-status-select-wrapper .ant-select-selection {
  background-color: @header-background;
  border: none;
  outline: none;
  &:focus, &:active {
    box-shadow: none;
  }
}

.editable-status-select-wrapper .ant-select-focused .ant-select-selection--single {
  background-color: @select-hover;
}

.editable-status-select-wrapper .ant-select-open .ant-select-selection,
.editable-status-select-wrapper .ant-select-focused .ant-select-selection {
  box-shadow: none;
  background-color: @select-hover;
}

.editable-status-select-wrapper .ant-select-arrow {
  right: 8px;
}

.editable-status-select-wrapper .ant-select-selection {
  &:hover {
    background-color: @select-hover;
  }
}

.ant-select-selection__placeholder {
  font-weight: 500;
  color: @menu-item-focused;
  font-size: 13px;
}

.editable-status-select-wrapper .ant-select-arrow svg {
  .select-arrow();
}

.ant-select-open .ant-select-arrow svg {
  fill: @menu-item-focused;
}

@primary-color: #3E82F1;