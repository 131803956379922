.buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  button,
  a {
    margin-left: 30px;
  }
}

.hidden {
  display: none;
}
