@import "../../styles/variables";

.create-story-container {
  min-width: 375px;
  h2 {
    font-size: 24px;
    color: @text;
    font-weight: 600;
    margin: 0 0 11px;
  }
  .story-information-container,
  .assignments-container,
  .platforms-container {
    background-color: @form-background;
    border-radius: 6px;
    .ant-row.ant-form-item {
      .ant-form-item-label {
        line-height: 16px;
        margin-bottom: 8px;
        label {
          font-size: 13px;
          color: @text;
        }
      }
      .ant-select {
        width: 100%;
        .ant-select-selection.ant-select-selection--single {
          height: 50px;
          .ant-select-selection__rendered {
            line-height: 48px;
            .ant-select-selection__placeholder {
              font-size: 15px;
              font-weight: 500;
              color: @placeholder;
            }
          }
        }
      }
      input.ant-input,
      textarea.ant-input {
        &::placeholder {
          font-size: 15px;
          font-weight: 500;
          color: @placeholder;
        }
      }
      textarea.ant-input {
        padding-top: 16px;
        margin-bottom: 0;
      }
    }
  }
  .assignments-container,
  .platforms-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    h3 {
      margin-bottom: 8px;
      padding: 20px 0 0 30px;
    }
    span.title {
      font: 15px "Montserrat", sans-serif;
      font-weight: 500;
      color: @text;
      padding-left: 30px;
    }
    button.green-outline.small-button {
      &.visible {
        display: block;
      }
      &.hidden {
        display: none;
      }
    }
  }
  .assignments-form,
  .platforms-form {
    margin-top: 20px;
    border-bottom: 1px solid @line;
    padding: 0 25px 0 30px;
    margin-left: -30px;
    margin-right: -30px;
  }
  .platforms-form {
    .platform-delete-container {
      display: flex;
      i.anticon {
        font-size: 24px;
        margin-left: auto;
      }
    }
  }
  .assignments-container {
    .assignments-form {
      .ant-row {
        &.ant-form-item {
          margin-bottom: 0;
        }
        &.row {
          margin-bottom: 20px;
          &:last-of-type {
            margin-bottom: 25px;
          }
        }
        .ant-form-item-control {
          .ant-radio-group {
            label.ant-radio-button-wrapper {
              height: 36px;
              margin-right: 15px;
              border-radius: 28px;
              background-color: @content-background;
              border: none;
              outline: none;
              &:last-of-type {
                margin-right: 0;
              }
              &:before {
                background-color: transparent;
              }
              &:hover {
                background-color: @green-outline-text;
                span {
                  color: @solid-white-text;
                }
              }
              &.ant-radio-button-wrapper-checked {
                background-color: @green-outline-text;
                box-shadow: none;
                &:focus {
                  outline: none;
                }
                &:before {
                  background-color: transparent !important;
                }
                &:hover {
                  box-shadow: none;
                }
                span {
                  color: @solid-white-text;
                }
              }
              span {
                vertical-align: middle;
                font: 15px "Montserrat", sans-serif;
                font-weight: 500;
                color: @menu-item;
                i.anticon {
                  margin-right: 4px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
    .custom-col {
      display: flex;
      flex-direction: column;
    }
    .user-creator span {
      font-weight: 600;
    }
    // assignments notify
    .notify-user-and-delete-container {
      padding-bottom: 20px;

      .ant-form-item-label {
        padding-left: 9px;
        margin-bottom: 0 !important;
        display: flex;
        align-items: center;
      }
      .ant-form-item {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
      }
      .ant-form-item-control-wrapper {
        margin-bottom: 0 !important;
      }
      display: flex;
      i.anticon {
        font-size: 24px;
        margin-left: auto;
      }
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
  }
  .story-information-container {
    margin-top: 10px;
    h3 {
      margin-bottom: 20px;
    }
    .ant-row.last-row {
      .ant-row.ant-form-item {
        margin-bottom: 0;
      }
    }
    .ant-row.ant-form-item {
      .ant-form-item-control {
        line-height: 0;
      }
    }
  }
  .issue-date-picker,
  .issue-time-picker {
    min-width: 130px;
  }
  // platforms status
  .platforms-status {
    margin-bottom: 0;
    .ant-form-item-control-wrapper {
      margin-bottom: 0 !important;
      .ant-form-item-control {
        line-height: unset !important;
      }
    }
  }
  // platforms
  .platform-row {
    margin: 0 !important;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
    @media (max-width: 991px) {
      padding-bottom: 10px;
    }
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .delete-col {
      padding: 0 !important;
      margin-left: auto;
      @media (max-width: 991px) {
        width: unset;
      }
    }
    .platform-col {
      padding-left: 0 !important;
      padding-right: 20px !important;
      max-width: 380px; // includes paddings
      @media (max-width: 1315px) {
        max-width: 320px;
      }
      @media (max-width: 1215px) {
        max-width: 260px;
      }
      @media (max-width: 1115px) {
        max-width: 230px;
      }
      @media (max-width: 991px) {
        padding-right: 0 !important;
        max-width: unset; // should be full width
      }
    }
    .publish-type-col {
      padding: 0 20px !important;
      max-width: 180px; // includes paddings
      @media (max-width: 991px) {
        padding-left: 0 !important;
      }
      @media (max-width: 768px) {
        min-width: 180px; // includes paddings
      }
    }
    .idea-col {
      padding: 0 20px !important;
      min-width: 84px; // includes paddings
      .ant-form-item-control-wrapper {
        margin-bottom: 57px !important; // to be in one line with others
      }
    }
    .publication_date-col {
      padding-left: 20px !important;
      padding-right: 15px !important;
      max-width: 200px; // includes paddings
    }
    .publication_time-col {
      padding-left: 15px !important;
      padding-right: 20px !important;
      max-width: 200px; // includes paddings
    }
    .status-col {
      .tag-text {
        font-size: 13px;
      }
      .status-dot {
        margin-right: 8px;
      }
      margin-right: -12px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      max-width: 144px; // includes paddings
    }
    .issue-col {
      padding: 0 20px !important;
      max-width: 400px; // includes paddings
      @media (max-width: 890px) {
        padding-left: 0 !important;
      }
    }
    @media (max-width: 890px) {
      .platform-col {
        min-width: 300px;
        width: 100%;
      }
      .publication_time-col {
        min-width: 165px;
      }
      .idea-col,
      .publication_date-col {
        width: 50%;
        padding-left: 0 !important;
      }
      .idea-col,
      .publication_date-col,
      .publication_time-col,
      .status-col {
        padding-right: 35px !important;
      }
      .status-col,
      .publication_time-col {
        width: 48%;
        padding-left: 0 !important;
      }
      .delete-col {
        width: 4%;
      }
    }
  }
  .create-story-row {
    .ant-row {
      margin-bottom: 0;
    }
  }
  .story-panel {
    .ant-card-head {
      padding-bottom: 0;
    }
    .ant-card-extra {
      padding-bottom: 0 !important;
    }
  }
  // upload
  .ant-upload-list-item,
  .ant-upload-list-item span {
    height: 16px;
  }
  .anticon-paper-clip {
    display: none;
  }
  .anticon-close {
    top: 2px !important;
  }
  .ant-upload-list-item-name {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: @placeholder;
    padding-left: 15px;
  }
  .ant-upload-list-item-info {
    background-repeat: no-repeat;
    background-size: 12px 13px;
    background-position: left;
    background-image: url("../../images/icons/icon-doc.svg");
  }

  .assignment-types-col {
    .ant-form-item-control-wrapper {
      margin-bottom: 0 !important;
    }

    .label {
      margin-bottom: 10px;
    }
  }
  // platforms time picker
  .ant-time-picker {
    width: unset;
    .ant-time-picker-input {
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .issue-col,
  .platform-col {
    @media (max-width: 991px) {
      padding: 0 !important;
    }
  }
}

.filters-tree-select-dropdown {
  .ant-select-tree-title {
    color: @text !important;
  }
}

.cascader-popup {
  .ant-cascader-menu {
    height: 292px;
  }
}

.platforms-status .editable-status-select {
  margin-top: -8px;
}

.assgiment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@primary-color: #3E82F1;