* {
  user-select: none;
}
body,
#root {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}
body .login-page-container a.back-to-login-link,
#root .login-page-container a.back-to-login-link,
body .reset-password-page-container a.back-to-login-link,
#root .reset-password-page-container a.back-to-login-link,
body .confirm-reset-password-page-container a.back-to-login-link,
#root .confirm-reset-password-page-container a.back-to-login-link,
body .create-story-container a.back-to-login-link,
#root .create-story-container a.back-to-login-link {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #3E3D47;
  font-weight: 500;
  line-height: 20px;
}
body .login-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .login-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .confirm-reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .confirm-reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .create-story-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .create-story-container a.back-to-login-link .anticon.anticon-arrow-left {
  font-size: 12px;
  margin-right: 5px;
  vertical-align: baseline;
}
body .login-page-container a.back-to-login-link:hover,
#root .login-page-container a.back-to-login-link:hover,
body .reset-password-page-container a.back-to-login-link:hover,
#root .reset-password-page-container a.back-to-login-link:hover,
body .confirm-reset-password-page-container a.back-to-login-link:hover,
#root .confirm-reset-password-page-container a.back-to-login-link:hover,
body .create-story-container a.back-to-login-link:hover,
#root .create-story-container a.back-to-login-link:hover {
  text-decoration: underline;
}
body .ant-form-item-label,
#root .ant-form-item-label {
  line-height: 16px;
}
body .ant-form-item-label label,
#root .ant-form-item-label label {
  font-size: 13px;
  color: #3E3D47;
}
body .login-page-wrapper,
#root .login-page-wrapper,
body .reset-password-page-wrapper,
#root .reset-password-page-wrapper,
body .confirm-reset-password-page-wrapper,
#root .confirm-reset-password-page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: -webkit-gradient(58.09deg, #8D71FF 7.59%, #0DEDED 101.29%);
  background: -moz-gradient(58.09deg, #8D71FF 7.59%, #0DEDED 101.29%);
  background: -ms-gradient(58.09deg, #8D71FF 7.59%, #0DEDED 101.29%);
  background: linear-gradient(58.09deg, #8D71FF 7.59%, #0DEDED 101.29%);
}
body .login-page-wrapper .login-page-container,
#root .login-page-wrapper .login-page-container,
body .reset-password-page-wrapper .login-page-container,
#root .reset-password-page-wrapper .login-page-container,
body .confirm-reset-password-page-wrapper .login-page-container,
#root .confirm-reset-password-page-wrapper .login-page-container,
body .login-page-wrapper .reset-password-page-container,
#root .login-page-wrapper .reset-password-page-container,
body .reset-password-page-wrapper .reset-password-page-container,
#root .reset-password-page-wrapper .reset-password-page-container,
body .confirm-reset-password-page-wrapper .reset-password-page-container,
#root .confirm-reset-password-page-wrapper .reset-password-page-container,
body .login-page-wrapper .confirm-reset-password-page-container,
#root .login-page-wrapper .confirm-reset-password-page-container,
body .reset-password-page-wrapper .confirm-reset-password-page-container,
#root .reset-password-page-wrapper .confirm-reset-password-page-container,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container {
  height: 100%;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
body .login-page-wrapper .login-page-container input.ant-input,
#root .login-page-wrapper .login-page-container input.ant-input,
body .reset-password-page-wrapper .login-page-container input.ant-input,
#root .reset-password-page-wrapper .login-page-container input.ant-input,
body .confirm-reset-password-page-wrapper .login-page-container input.ant-input,
#root .confirm-reset-password-page-wrapper .login-page-container input.ant-input,
body .login-page-wrapper .reset-password-page-container input.ant-input,
#root .login-page-wrapper .reset-password-page-container input.ant-input,
body .reset-password-page-wrapper .reset-password-page-container input.ant-input,
#root .reset-password-page-wrapper .reset-password-page-container input.ant-input,
body .confirm-reset-password-page-wrapper .reset-password-page-container input.ant-input,
#root .confirm-reset-password-page-wrapper .reset-password-page-container input.ant-input,
body .login-page-wrapper .confirm-reset-password-page-container input.ant-input,
#root .login-page-wrapper .confirm-reset-password-page-container input.ant-input,
body .reset-password-page-wrapper .confirm-reset-password-page-container input.ant-input,
#root .reset-password-page-wrapper .confirm-reset-password-page-container input.ant-input,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container input.ant-input,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container input.ant-input {
  height: 41px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
}
body .login-page-wrapper .login-page-container .header-container h1,
#root .login-page-wrapper .login-page-container .header-container h1,
body .reset-password-page-wrapper .login-page-container .header-container h1,
#root .reset-password-page-wrapper .login-page-container .header-container h1,
body .confirm-reset-password-page-wrapper .login-page-container .header-container h1,
#root .confirm-reset-password-page-wrapper .login-page-container .header-container h1,
body .login-page-wrapper .reset-password-page-container .header-container h1,
#root .login-page-wrapper .reset-password-page-container .header-container h1,
body .reset-password-page-wrapper .reset-password-page-container .header-container h1,
#root .reset-password-page-wrapper .reset-password-page-container .header-container h1,
body .confirm-reset-password-page-wrapper .reset-password-page-container .header-container h1,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .header-container h1,
body .login-page-wrapper .confirm-reset-password-page-container .header-container h1,
#root .login-page-wrapper .confirm-reset-password-page-container .header-container h1,
body .reset-password-page-wrapper .confirm-reset-password-page-container .header-container h1,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .header-container h1,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .header-container h1,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .header-container h1 {
  color: #FFFFFF;
  font-size: 46px;
  margin-bottom: 15px;
}
body .login-page-wrapper .login-page-container a.back-to-login-link,
#root .login-page-wrapper .login-page-container a.back-to-login-link,
body .reset-password-page-wrapper .login-page-container a.back-to-login-link,
#root .reset-password-page-wrapper .login-page-container a.back-to-login-link,
body .confirm-reset-password-page-wrapper .login-page-container a.back-to-login-link,
#root .confirm-reset-password-page-wrapper .login-page-container a.back-to-login-link,
body .login-page-wrapper .reset-password-page-container a.back-to-login-link,
#root .login-page-wrapper .reset-password-page-container a.back-to-login-link,
body .reset-password-page-wrapper .reset-password-page-container a.back-to-login-link,
#root .reset-password-page-wrapper .reset-password-page-container a.back-to-login-link,
body .confirm-reset-password-page-wrapper .reset-password-page-container a.back-to-login-link,
#root .confirm-reset-password-page-wrapper .reset-password-page-container a.back-to-login-link,
body .login-page-wrapper .confirm-reset-password-page-container a.back-to-login-link,
#root .login-page-wrapper .confirm-reset-password-page-container a.back-to-login-link,
body .reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link,
#root .reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link {
  font-size: 16px;
  color: #3E3D47;
  font-weight: 500;
  line-height: 20px;
}
body .login-page-wrapper .login-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .login-page-wrapper .login-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .reset-password-page-wrapper .login-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .reset-password-page-wrapper .login-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .confirm-reset-password-page-wrapper .login-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .confirm-reset-password-page-wrapper .login-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .login-page-wrapper .reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .login-page-wrapper .reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .reset-password-page-wrapper .reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .reset-password-page-wrapper .reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .confirm-reset-password-page-wrapper .reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .confirm-reset-password-page-wrapper .reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .login-page-wrapper .confirm-reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .login-page-wrapper .confirm-reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link .anticon.anticon-arrow-left {
  font-size: 12px;
  margin-right: 5px;
  vertical-align: baseline;
}
body .login-page-wrapper .login-page-container a.back-to-login-link:hover,
#root .login-page-wrapper .login-page-container a.back-to-login-link:hover,
body .reset-password-page-wrapper .login-page-container a.back-to-login-link:hover,
#root .reset-password-page-wrapper .login-page-container a.back-to-login-link:hover,
body .confirm-reset-password-page-wrapper .login-page-container a.back-to-login-link:hover,
#root .confirm-reset-password-page-wrapper .login-page-container a.back-to-login-link:hover,
body .login-page-wrapper .reset-password-page-container a.back-to-login-link:hover,
#root .login-page-wrapper .reset-password-page-container a.back-to-login-link:hover,
body .reset-password-page-wrapper .reset-password-page-container a.back-to-login-link:hover,
#root .reset-password-page-wrapper .reset-password-page-container a.back-to-login-link:hover,
body .confirm-reset-password-page-wrapper .reset-password-page-container a.back-to-login-link:hover,
#root .confirm-reset-password-page-wrapper .reset-password-page-container a.back-to-login-link:hover,
body .login-page-wrapper .confirm-reset-password-page-container a.back-to-login-link:hover,
#root .login-page-wrapper .confirm-reset-password-page-container a.back-to-login-link:hover,
body .reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link:hover,
#root .reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link:hover,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link:hover,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container a.back-to-login-link:hover {
  text-decoration: underline;
}
body .login-page-wrapper .login-page-container .reset-password-form-title,
#root .login-page-wrapper .login-page-container .reset-password-form-title,
body .reset-password-page-wrapper .login-page-container .reset-password-form-title,
#root .reset-password-page-wrapper .login-page-container .reset-password-form-title,
body .confirm-reset-password-page-wrapper .login-page-container .reset-password-form-title,
#root .confirm-reset-password-page-wrapper .login-page-container .reset-password-form-title,
body .login-page-wrapper .reset-password-page-container .reset-password-form-title,
#root .login-page-wrapper .reset-password-page-container .reset-password-form-title,
body .reset-password-page-wrapper .reset-password-page-container .reset-password-form-title,
#root .reset-password-page-wrapper .reset-password-page-container .reset-password-form-title,
body .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form-title,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form-title,
body .login-page-wrapper .confirm-reset-password-page-container .reset-password-form-title,
#root .login-page-wrapper .confirm-reset-password-page-container .reset-password-form-title,
body .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title {
  text-align: center;
}
body .login-page-wrapper .login-page-container .reset-password-form-title h2,
#root .login-page-wrapper .login-page-container .reset-password-form-title h2,
body .reset-password-page-wrapper .login-page-container .reset-password-form-title h2,
#root .reset-password-page-wrapper .login-page-container .reset-password-form-title h2,
body .confirm-reset-password-page-wrapper .login-page-container .reset-password-form-title h2,
#root .confirm-reset-password-page-wrapper .login-page-container .reset-password-form-title h2,
body .login-page-wrapper .reset-password-page-container .reset-password-form-title h2,
#root .login-page-wrapper .reset-password-page-container .reset-password-form-title h2,
body .reset-password-page-wrapper .reset-password-page-container .reset-password-form-title h2,
#root .reset-password-page-wrapper .reset-password-page-container .reset-password-form-title h2,
body .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form-title h2,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form-title h2,
body .login-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
#root .login-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
body .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2 {
  margin: 45px 0 40px;
}
body .login-page-wrapper .login-page-container .social-networks-section h2,
#root .login-page-wrapper .login-page-container .social-networks-section h2,
body .reset-password-page-wrapper .login-page-container .social-networks-section h2,
#root .reset-password-page-wrapper .login-page-container .social-networks-section h2,
body .confirm-reset-password-page-wrapper .login-page-container .social-networks-section h2,
#root .confirm-reset-password-page-wrapper .login-page-container .social-networks-section h2,
body .login-page-wrapper .reset-password-page-container .social-networks-section h2,
#root .login-page-wrapper .reset-password-page-container .social-networks-section h2,
body .reset-password-page-wrapper .reset-password-page-container .social-networks-section h2,
#root .reset-password-page-wrapper .reset-password-page-container .social-networks-section h2,
body .confirm-reset-password-page-wrapper .reset-password-page-container .social-networks-section h2,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .social-networks-section h2,
body .login-page-wrapper .confirm-reset-password-page-container .social-networks-section h2,
#root .login-page-wrapper .confirm-reset-password-page-container .social-networks-section h2,
body .reset-password-page-wrapper .confirm-reset-password-page-container .social-networks-section h2,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .social-networks-section h2,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .social-networks-section h2,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .social-networks-section h2,
body .login-page-wrapper .login-page-container .reset-password-form-title h2,
#root .login-page-wrapper .login-page-container .reset-password-form-title h2,
body .reset-password-page-wrapper .login-page-container .reset-password-form-title h2,
#root .reset-password-page-wrapper .login-page-container .reset-password-form-title h2,
body .confirm-reset-password-page-wrapper .login-page-container .reset-password-form-title h2,
#root .confirm-reset-password-page-wrapper .login-page-container .reset-password-form-title h2,
body .login-page-wrapper .reset-password-page-container .reset-password-form-title h2,
#root .login-page-wrapper .reset-password-page-container .reset-password-form-title h2,
body .reset-password-page-wrapper .reset-password-page-container .reset-password-form-title h2,
#root .reset-password-page-wrapper .reset-password-page-container .reset-password-form-title h2,
body .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form-title h2,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form-title h2,
body .login-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
#root .login-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
body .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form-title h2 {
  font-size: 20px;
  font-weight: 600;
  color: #3E3D47;
}
body .login-page-wrapper .login-page-container .login-form,
#root .login-page-wrapper .login-page-container .login-form,
body .reset-password-page-wrapper .login-page-container .login-form,
#root .reset-password-page-wrapper .login-page-container .login-form,
body .confirm-reset-password-page-wrapper .login-page-container .login-form,
#root .confirm-reset-password-page-wrapper .login-page-container .login-form,
body .login-page-wrapper .reset-password-page-container .login-form,
#root .login-page-wrapper .reset-password-page-container .login-form,
body .reset-password-page-wrapper .reset-password-page-container .login-form,
#root .reset-password-page-wrapper .reset-password-page-container .login-form,
body .confirm-reset-password-page-wrapper .reset-password-page-container .login-form,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .login-form,
body .login-page-wrapper .confirm-reset-password-page-container .login-form,
#root .login-page-wrapper .confirm-reset-password-page-container .login-form,
body .reset-password-page-wrapper .confirm-reset-password-page-container .login-form,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .login-form,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .login-form,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .login-form,
body .login-page-wrapper .login-page-container .reset-password-form,
#root .login-page-wrapper .login-page-container .reset-password-form,
body .reset-password-page-wrapper .login-page-container .reset-password-form,
#root .reset-password-page-wrapper .login-page-container .reset-password-form,
body .confirm-reset-password-page-wrapper .login-page-container .reset-password-form,
#root .confirm-reset-password-page-wrapper .login-page-container .reset-password-form,
body .login-page-wrapper .reset-password-page-container .reset-password-form,
#root .login-page-wrapper .reset-password-page-container .reset-password-form,
body .reset-password-page-wrapper .reset-password-page-container .reset-password-form,
#root .reset-password-page-wrapper .reset-password-page-container .reset-password-form,
body .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form,
body .login-page-wrapper .confirm-reset-password-page-container .reset-password-form,
#root .login-page-wrapper .confirm-reset-password-page-container .reset-password-form,
body .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
body .login-page-wrapper .login-page-container .confirm-reset-password-form,
#root .login-page-wrapper .login-page-container .confirm-reset-password-form,
body .reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
#root .reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
body .confirm-reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
#root .confirm-reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
body .login-page-wrapper .reset-password-page-container .confirm-reset-password-form,
#root .login-page-wrapper .reset-password-page-container .confirm-reset-password-form,
body .reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
#root .reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
body .confirm-reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
body .login-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
#root .login-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
body .reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form {
  width: 414px;
  background-color: #FFFFFF;
  border-radius: 10px;
}
@media screen and (max-width: 767px) {
  body .login-page-wrapper .login-page-container .login-form,
  #root .login-page-wrapper .login-page-container .login-form,
  body .reset-password-page-wrapper .login-page-container .login-form,
  #root .reset-password-page-wrapper .login-page-container .login-form,
  body .confirm-reset-password-page-wrapper .login-page-container .login-form,
  #root .confirm-reset-password-page-wrapper .login-page-container .login-form,
  body .login-page-wrapper .reset-password-page-container .login-form,
  #root .login-page-wrapper .reset-password-page-container .login-form,
  body .reset-password-page-wrapper .reset-password-page-container .login-form,
  #root .reset-password-page-wrapper .reset-password-page-container .login-form,
  body .confirm-reset-password-page-wrapper .reset-password-page-container .login-form,
  #root .confirm-reset-password-page-wrapper .reset-password-page-container .login-form,
  body .login-page-wrapper .confirm-reset-password-page-container .login-form,
  #root .login-page-wrapper .confirm-reset-password-page-container .login-form,
  body .reset-password-page-wrapper .confirm-reset-password-page-container .login-form,
  #root .reset-password-page-wrapper .confirm-reset-password-page-container .login-form,
  body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .login-form,
  #root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .login-form,
  body .login-page-wrapper .login-page-container .reset-password-form,
  #root .login-page-wrapper .login-page-container .reset-password-form,
  body .reset-password-page-wrapper .login-page-container .reset-password-form,
  #root .reset-password-page-wrapper .login-page-container .reset-password-form,
  body .confirm-reset-password-page-wrapper .login-page-container .reset-password-form,
  #root .confirm-reset-password-page-wrapper .login-page-container .reset-password-form,
  body .login-page-wrapper .reset-password-page-container .reset-password-form,
  #root .login-page-wrapper .reset-password-page-container .reset-password-form,
  body .reset-password-page-wrapper .reset-password-page-container .reset-password-form,
  #root .reset-password-page-wrapper .reset-password-page-container .reset-password-form,
  body .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form,
  #root .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form,
  body .login-page-wrapper .confirm-reset-password-page-container .reset-password-form,
  #root .login-page-wrapper .confirm-reset-password-page-container .reset-password-form,
  body .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
  #root .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
  body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
  #root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
  body .login-page-wrapper .login-page-container .confirm-reset-password-form,
  #root .login-page-wrapper .login-page-container .confirm-reset-password-form,
  body .reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
  #root .reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
  body .confirm-reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
  #root .confirm-reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
  body .login-page-wrapper .reset-password-page-container .confirm-reset-password-form,
  #root .login-page-wrapper .reset-password-page-container .confirm-reset-password-form,
  body .reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
  #root .reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
  body .confirm-reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
  #root .confirm-reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
  body .login-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
  #root .login-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
  body .reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
  #root .reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
  body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
  #root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form {
    width: 100%;
  }
}
body .login-page-wrapper .login-page-container .login-form button.solid-blue,
#root .login-page-wrapper .login-page-container .login-form button.solid-blue,
body .reset-password-page-wrapper .login-page-container .login-form button.solid-blue,
#root .reset-password-page-wrapper .login-page-container .login-form button.solid-blue,
body .confirm-reset-password-page-wrapper .login-page-container .login-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .login-page-container .login-form button.solid-blue,
body .login-page-wrapper .reset-password-page-container .login-form button.solid-blue,
#root .login-page-wrapper .reset-password-page-container .login-form button.solid-blue,
body .reset-password-page-wrapper .reset-password-page-container .login-form button.solid-blue,
#root .reset-password-page-wrapper .reset-password-page-container .login-form button.solid-blue,
body .confirm-reset-password-page-wrapper .reset-password-page-container .login-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .login-form button.solid-blue,
body .login-page-wrapper .confirm-reset-password-page-container .login-form button.solid-blue,
#root .login-page-wrapper .confirm-reset-password-page-container .login-form button.solid-blue,
body .reset-password-page-wrapper .confirm-reset-password-page-container .login-form button.solid-blue,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .login-form button.solid-blue,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .login-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .login-form button.solid-blue,
body .login-page-wrapper .login-page-container .reset-password-form button.solid-blue,
#root .login-page-wrapper .login-page-container .reset-password-form button.solid-blue,
body .reset-password-page-wrapper .login-page-container .reset-password-form button.solid-blue,
#root .reset-password-page-wrapper .login-page-container .reset-password-form button.solid-blue,
body .confirm-reset-password-page-wrapper .login-page-container .reset-password-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .login-page-container .reset-password-form button.solid-blue,
body .login-page-wrapper .reset-password-page-container .reset-password-form button.solid-blue,
#root .login-page-wrapper .reset-password-page-container .reset-password-form button.solid-blue,
body .reset-password-page-wrapper .reset-password-page-container .reset-password-form button.solid-blue,
#root .reset-password-page-wrapper .reset-password-page-container .reset-password-form button.solid-blue,
body .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form button.solid-blue,
body .login-page-wrapper .confirm-reset-password-page-container .reset-password-form button.solid-blue,
#root .login-page-wrapper .confirm-reset-password-page-container .reset-password-form button.solid-blue,
body .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form button.solid-blue,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form button.solid-blue,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form button.solid-blue,
body .login-page-wrapper .login-page-container .confirm-reset-password-form button.solid-blue,
#root .login-page-wrapper .login-page-container .confirm-reset-password-form button.solid-blue,
body .reset-password-page-wrapper .login-page-container .confirm-reset-password-form button.solid-blue,
#root .reset-password-page-wrapper .login-page-container .confirm-reset-password-form button.solid-blue,
body .confirm-reset-password-page-wrapper .login-page-container .confirm-reset-password-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .login-page-container .confirm-reset-password-form button.solid-blue,
body .login-page-wrapper .reset-password-page-container .confirm-reset-password-form button.solid-blue,
#root .login-page-wrapper .reset-password-page-container .confirm-reset-password-form button.solid-blue,
body .reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form button.solid-blue,
#root .reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form button.solid-blue,
body .confirm-reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form button.solid-blue,
body .login-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form button.solid-blue,
#root .login-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form button.solid-blue,
body .reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form button.solid-blue,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form button.solid-blue,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form button.solid-blue,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form button.solid-blue {
  display: flex;
  width: 100%;
  justify-content: center;
}
body .login-page-wrapper .login-page-container .reset-password-form,
#root .login-page-wrapper .login-page-container .reset-password-form,
body .reset-password-page-wrapper .login-page-container .reset-password-form,
#root .reset-password-page-wrapper .login-page-container .reset-password-form,
body .confirm-reset-password-page-wrapper .login-page-container .reset-password-form,
#root .confirm-reset-password-page-wrapper .login-page-container .reset-password-form,
body .login-page-wrapper .reset-password-page-container .reset-password-form,
#root .login-page-wrapper .reset-password-page-container .reset-password-form,
body .reset-password-page-wrapper .reset-password-page-container .reset-password-form,
#root .reset-password-page-wrapper .reset-password-page-container .reset-password-form,
body .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .reset-password-form,
body .login-page-wrapper .confirm-reset-password-page-container .reset-password-form,
#root .login-page-wrapper .confirm-reset-password-page-container .reset-password-form,
body .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .reset-password-form,
body .login-page-wrapper .login-page-container .confirm-reset-password-form,
#root .login-page-wrapper .login-page-container .confirm-reset-password-form,
body .reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
#root .reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
body .confirm-reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
#root .confirm-reset-password-page-wrapper .login-page-container .confirm-reset-password-form,
body .login-page-wrapper .reset-password-page-container .confirm-reset-password-form,
#root .login-page-wrapper .reset-password-page-container .confirm-reset-password-form,
body .reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
#root .reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
body .confirm-reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
#root .confirm-reset-password-page-wrapper .reset-password-page-container .confirm-reset-password-form,
body .login-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
#root .login-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
body .reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
#root .reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
body .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form,
#root .confirm-reset-password-page-wrapper .confirm-reset-password-page-container .confirm-reset-password-form {
  padding: 15px 37px 50px;
}
body .login-page-wrapper .footer-container,
#root .login-page-wrapper .footer-container,
body .reset-password-page-wrapper .footer-container,
#root .reset-password-page-wrapper .footer-container,
body .confirm-reset-password-page-wrapper .footer-container,
#root .confirm-reset-password-page-wrapper .footer-container {
  color: rgba(255, 255, 255, 0.7);
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
body .create-story-container .story-information-container .ant-form-item-control span.ant-form-item-children input.ant-input,
#root .create-story-container .story-information-container .ant-form-item-control span.ant-form-item-children input.ant-input,
body .create-story-container .assignments-container .ant-form-item-control span.ant-form-item-children input.ant-input,
#root .create-story-container .assignments-container .ant-form-item-control span.ant-form-item-children input.ant-input {
  height: 50px;
}
body .create-story-container .platforms-container .platforms-form .ant-form-item-control,
#root .create-story-container .platforms-container .platforms-form .ant-form-item-control {
  line-height: 0;
}
body .create-story-container .platforms-container .platforms-form .ant-form-item-control span.ant-form-item-children input.ant-calendar-picker-input.ant-input,
#root .create-story-container .platforms-container .platforms-form .ant-form-item-control span.ant-form-item-children input.ant-calendar-picker-input.ant-input {
  height: 50px;
}
body .create-story-container .platforms-container .platforms-form .ant-form-item-control span.ant-time-picker input.ant-time-picker-input,
#root .create-story-container .platforms-container .platforms-form .ant-form-item-control span.ant-time-picker input.ant-time-picker-input {
  height: 50px;
}
body button.ant-btn,
#root button.ant-btn {
  height: 44px;
}
body .login-page-container .ant-spin span.ant-spin-dot,
#root .login-page-container .ant-spin span.ant-spin-dot,
body .brand-card-list-wrapper .ant-spin span.ant-spin-dot,
#root .brand-card-list-wrapper .ant-spin span.ant-spin-dot {
  width: 100px;
  height: 100px;
}
body .login-page-container .ant-spin span.ant-spin-dot i.ant-spin-dot-item,
#root .login-page-container .ant-spin span.ant-spin-dot i.ant-spin-dot-item,
body .brand-card-list-wrapper .ant-spin span.ant-spin-dot i.ant-spin-dot-item,
#root .brand-card-list-wrapper .ant-spin span.ant-spin-dot i.ant-spin-dot-item {
  width: 50px;
  height: 50px;
  background-color: #0DEDED;
}
@media screen and (min-width: 1200px) {
  .align-right-lg {
    text-align: right;
  }
}
.has-error .ant-form-explain {
  margin-top: 5px;
}
.page-title {
  margin-top: 0 !important;
  margin-bottom: 11px !important;
}
.simple-message-card .ant-card-head-title {
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #3E3D47;
}
.bold {
  font-weight: 600;
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v13-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('../fonts/montserrat-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/montserrat-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/montserrat-v13-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/montserrat-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/montserrat-v13-latin-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v13-latin-500.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('../fonts/montserrat-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/montserrat-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/montserrat-v13-latin-500.woff') format('woff'), /* Modern Browsers */ url('../fonts/montserrat-v13-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/montserrat-v13-latin-500.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v13-latin-600.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('../fonts/montserrat-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/montserrat-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/montserrat-v13-latin-600.woff') format('woff'), /* Modern Browsers */ url('../fonts/montserrat-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/montserrat-v13-latin-600.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v13-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('../fonts/montserrat-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/montserrat-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/montserrat-v13-latin-700.woff') format('woff'), /* Modern Browsers */ url('../fonts/montserrat-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/montserrat-v13-latin-700.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
.user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button-common {
  font-weight: bold;
  font-style: normal;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
}
.button-common:disabled {
  cursor: not-allowed;
}
.solid-blue-disabled {
  background-color: #E8EBF8;
  color: #C9C9C9;
}
.solid-blue-disabled path {
  fill: #C9C9C9;
}
.blue-outline-disabled {
  border: 1px solid #C9C9C9;
  background-color: transparent;
  color: #C9C9C9;
}
.blue-outline-disabled path {
  fill: #C9C9C9;
}
.green-outline-disabled {
  border: 1px solid #C9C9C9;
  background-color: transparent;
  color: #C9C9C9;
}
.green-outline-disabled .button-icon path {
  fill: #C9C9C9;
}
.select-arrow {
  width: 17px;
  height: 17px;
  fill: #D2D5DC;
}
.search-input-common:hover {
  outline: none;
  border-color: #d9d9d9;
  background-color: #E9FAFF;
}
.search-input-common:hover input {
  outline: none;
  border-color: #d9d9d9 !important;
  background-color: #E9FAFF;
}
.search-input-common input {
  padding-left: 35px !important;
}
.search-input-common input::placeholder {
  font-weight: 500;
  color: #9CA1AC;
}
.search-input-common input:focus,
.search-input-common input:hover {
  outline: none;
  border-color: #d9d9d9;
  background-color: #E9FAFF;
  box-shadow: none;
}
.search-input-common .ant-input-prefix {
  z-index: 1;
}
/**
modules
 */
.ant-card {
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(216, 221, 232, 0.8);
  border-radius: 6px;
}
.ant-card.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.ant-card .ant-card-head {
  padding: 20px 30px;
  border-bottom: none;
}
.ant-card .ant-card-head-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ant-card .ant-card-head-wrapper .ant-card-extra {
  margin-left: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding: 8px 0;
}
.ant-card .ant-card-head .ant-card-head-title {
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.ant-card .ant-card-body {
  padding: 0 30px;
}
.ant-card .ant-card-actions {
  background: transparent;
  padding: 25px 30px;
  border-top: none;
}
.ant-card .ant-card-actions > li {
  margin: 0;
  text-align: left;
}
.ant-card.story-panel,
.ant-card.commission-details-panel,
.ant-card.contributor-panel,
.ant-card.special-terms-panel {
  margin-bottom: 30px;
}
.ant-card.story-panel .ant-col.ant-form-item-control-wrapper,
.ant-card.commission-details-panel .ant-col.ant-form-item-control-wrapper,
.ant-card.contributor-panel .ant-col.ant-form-item-control-wrapper,
.ant-card.special-terms-panel .ant-col.ant-form-item-control-wrapper {
  margin-bottom: 30px;
}
.ant-card.story-panel .ant-row-flex.bordered,
.ant-card.commission-details-panel .ant-row-flex.bordered,
.ant-card.contributor-panel .ant-row-flex.bordered,
.ant-card.special-terms-panel .ant-row-flex.bordered {
  border-bottom: 0.4px solid #9ca1ace8;
  margin-left: -30px !important;
  margin-right: -30px !important;
  padding: 20px 20px 0;
}
.ant-card.story-panel .ant-row-flex.bordered .ant-col,
.ant-card.commission-details-panel .ant-row-flex.bordered .ant-col,
.ant-card.contributor-panel .ant-row-flex.bordered .ant-col,
.ant-card.special-terms-panel .ant-row-flex.bordered .ant-col {
  margin-bottom: 20px;
}
.ant-card.story-panel .ant-row-flex.bordered:last-child,
.ant-card.commission-details-panel .ant-row-flex.bordered:last-child,
.ant-card.contributor-panel .ant-row-flex.bordered:last-child,
.ant-card.special-terms-panel .ant-row-flex.bordered:last-child {
  border-bottom: none;
}
.ant-card.story-panel .ant-row-flex .ant-col,
.ant-card.commission-details-panel .ant-row-flex .ant-col,
.ant-card.contributor-panel .ant-row-flex .ant-col,
.ant-card.special-terms-panel .ant-row-flex .ant-col {
  margin-bottom: 30px;
}
.ant-card.story-panel .ant-row-flex .ant-col:first-child,
.ant-card.commission-details-panel .ant-row-flex .ant-col:first-child,
.ant-card.contributor-panel .ant-row-flex .ant-col:first-child,
.ant-card.special-terms-panel .ant-row-flex .ant-col:first-child {
  padding-left: 0;
}
.ant-card.story-panel .ant-row-flex .ant-col:last-child,
.ant-card.commission-details-panel .ant-row-flex .ant-col:last-child,
.ant-card.contributor-panel .ant-row-flex .ant-col:last-child,
.ant-card.special-terms-panel .ant-row-flex .ant-col:last-child {
  padding-right: 0;
}
.brand-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #b3b9c6;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
}
.buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.buttons-container button,
.buttons-container a {
  margin-left: 30px;
}
.hidden {
  display: none;
}
.anticon:hover {
  cursor: pointer;
}
.assignment-icon {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
}
.assignment-icon.paid_media {
  background-image: url("../images/icons/assignment-paid-media.svg");
}
.assignment-icon.text {
  background-image: url("../images/icons/assignment-text.svg");
}
.assignment-icon.audio {
  background-image: url("../images/icons/assignment-audio.svg");
}
.assignment-icon.image {
  background-image: url("../images/icons/assignment-image.svg");
}
.assignment-icon.services {
  background-image: url("../images/icons/assignment-service.svg");
}
.assignment-icon.media_via_subscription {
  background-image: url("../images/icons/assignment-subscription.svg");
}
.assignment-icon.free_media {
  background-image: url("../images/icons/assignment-free-media.svg");
}
.assignment-icon.video {
  background-image: url("../images/icons/assignment-video.svg");
}
.ant-input,
.ant-select-selection__rendered {
  font-size: 15px;
  font-weight: 500;
}
.modal-container {
  max-width: 414px;
  background-color: #FFFFFF;
  box-shadow: 4px 6px 16px rgba(160, 166, 178, 0.8);
  border-radius: 6px;
  padding-bottom: 0;
}
.modal-container .ant-modal-title {
  color: #3E3D47;
  margin: 0 50px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.modal-container .ant-modal-header {
  border-bottom: none;
  padding: 30px 24px 25px 24px;
}
.modal-container .story-list-modal-content-paragraph {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #3E3D47;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.modal-container .ant-modal-body {
  padding: 0 20px 10px 20px;
}
.modal-container .ant-modal-footer {
  border-top: none;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
}
.modal-container .modal-edit-button {
  margin-left: auto;
}
.modal-container .label {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #3E3D47;
}
.modal-container .ant-form-item {
  margin-top: 8px;
  margin-right: 0;
  display: block;
  min-height: 100px;
}
.modal-container .ant-form-item .ant-form-item-control-wrapper {
  width: 100%;
}
.modal-container .ant-form-item .ant-form-item-control-wrapper textarea {
  min-height: 100px;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.4);
}
.back-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #3E3D47;
  margin-bottom: 20px;
}
.back-link .anticon {
  margin-right: 5px;
}
.back-link .anticon svg {
  width: 10px;
  height: 10px;
}
.back-link:hover {
  text-decoration: underline;
  color: #3E3D47;
}
.ant-cascader-picker-label {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #3E3D47;
}
.hasValue {
  background-color: #E9FAFF !important;
}
.hasValue .ant-select-selection,
.hasValue .ant-calendar-picker-input,
.hasValue .ant-time-picker-input {
  background-color: #E9FAFF !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #E9FAFF inset !important;
}
.ant-pagination-item,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: none !important;
}
.ant-pagination-options {
  float: left;
}
.ant-table-pagination {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #3E3D47;
}
.ant-pagination.ant-table-pagination {
  margin-right: 30px;
}
li.ant-pagination-next,
li.ant-pagination-prev {
  color: #3E3D47;
}
.pagination-arrow-back svg {
  transform: rotate(180deg);
}
.pagination-arrow-next,
.pagination-arrow-back {
  font-family: "Montserrat", sans-serif;
  display: inline-flex;
  align-items: center;
}
.pagination-arrow-next span,
.pagination-arrow-back span {
  padding: 0 5px;
  display: inline-flex;
  align-items: center;
}
li.ant-pagination-disabled {
  color: #D2D5DC;
}
li.ant-pagination-disabled .pagination-arrow-next path,
li.ant-pagination-disabled .pagination-arrow-back path {
  stroke: #D2D5DC;
}
.ant-pagination-total-text {
  color: #9CA1AC;
}
.ant-pagination-options .ant-pagination-options-size-changer {
  font-size: 13px;
}
.ant-pagination-options .ant-select-dropdown-menu-item {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}
.ant-pagination-options-size-changer .ant-select-selection-selected-value {
  font-size: 13px;
  color: #3E3D47;
}
.ant-pagination-options-size-changer .ant-select-selection--single {
  background-color: #FFFFFF;
  border: none;
  outline: none;
}
.ant-pagination-options-size-changer .ant-select-selection--single:focus,
.ant-pagination-options-size-changer .ant-select-selection--single:active {
  box-shadow: none;
}
.ant-pagination-options .ant-select-focused .ant-select-selection--single {
  background-color: #E9FAFF;
}
input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.anticon-close-circle,
.ant-select-selection__clear {
  border-radius: 6px;
}
.anticon-close-circle svg {
  width: 14px;
  height: 14px;
  background: #E9FAFF;
}
.ant-select-selection__clear {
  right: 15px !important;
}
@-moz-document url-prefix() {
  .ant-select-dropdown-menu-item {
    overflow: visible;
  }
}
textarea {
  resize: none;
  /* Disables resizability */
}
