.ant-card {
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(216, 221, 232, 0.8);
  border-radius: 6px;

  &.no-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-card-head {
    padding: 20px 30px;
    border-bottom: none;
    &-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .ant-card-extra {
        margin-left: 0;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        padding: 8px 0;
      }
    }
    .ant-card-head-title {
      padding: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .ant-card-body {
    padding: 0 30px;
  }
  .ant-card-actions {
    background: transparent;
    padding: 25px 30px;
    border-top: none;
    > li {
      margin: 0;
      text-align: left;
    }
  }

  &.story-panel, &.commission-details-panel, &.contributor-panel, &.special-terms-panel {
    margin-bottom: 30px;
    .ant-col.ant-form-item-control-wrapper {
      margin-bottom: 30px;
    }
    .ant-row-flex {
      &.bordered {
        border-bottom: 0.4px solid #9ca1ace8;
        margin-left: -30px !important;
        margin-right: -30px !important;
        padding: 20px 20px 0;
        .ant-col {
          margin-bottom: 20px;
        }
        &:last-child {
          border-bottom: none;
        }
      }
      .ant-col {
        margin-bottom: 30px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
