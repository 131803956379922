@import "../../styles/variables";

.settings-wrapper {
  .ant-input-affix-wrapper {
    max-width: 389px;
  }
  input {
    height: 50px;
    max-width: 389px;
  }
  .ant-card-head {
    padding-bottom: 0 !important;
  }
  .ant-card-body {
    padding-bottom: 30px !important;
  }
  .integration-row {
    border-bottom: 1px solid @line;
    .brand-wrapper {
      margin: 30px 0 30px 0;
      display: flex;
      align-items: center;
    }
    .brand-name {
      padding-left: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: @text;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .brand-arrow {
      display: inline-flex;
      align-items: center;
      margin: 30px 20px 30px 0;
      transition: all 0.5s;
    }
    .expanded {
      transform: rotate(90deg);
    }
  }
  .arrow-brand-wrapper {
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }
  // switch
  .settings-switch {
    margin: 0 0 30px 30px;
    .switch-title {
      padding-left: 10px;
      font-size: 13px;
      line-height: 16px;
      color: @text;
    }
  }
  .forms-wrapper {
    display: flex;
    flex-direction: row;
    @media(max-width: 768px) {
      flex-direction: column;
    }
  }
  // wordpress form
  .wordpress-form {
    .ant-form-item-control-wrapper {
      margin-bottom: 0 !important;
    }
  }
  .ant-col {
    padding-left: 0 !important;
  }
  .wordpress-form, .integration-view {
    padding-left: 30px;
  }
  .label {
    color: @text;
  }
  .form-save-button {
    margin-left: 30px;
  }
  .integration-view {
    .label-description-wrapper:not(:first-child) {
      margin-top: 50px;
    }
    .integration-view-button-wrapper {
      display: flex;
      align-items: center;
      margin: 50px 0 20px 0;
      .connection-button {
        margin-left: 30px;
      }
      .check-connection {
        padding-left: 10px;
      }
    }
  }
  // sftp form
  .two-fields-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .label-description-wrapper-part:first-child {
      margin-right: 30px;
    }
    .port-field {
      flex-basis: 29%;
      input {
        max-width: 165px;
      }
    }
    .url-field {
      flex-basis: 71%;
    }
  }
  .ant-form-item-control {
    line-height: unset;
  }
}
@primary-color: #3E82F1;