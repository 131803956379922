@import "../../styles/mixins";
@import "../../styles/variables";

// common
.large-font-link {
  line-height: 21px;
}

.small-font-link {
  line-height: 16px;
}
button.small-button {
  padding: 0 10px;
}
.small-button {
  min-height: 30px;
  .button-icon {
    width: 12px;
    height: 12px;
  }
  .button-text {
    font-size: 13px;
    font-weight: 600;
  }
}

.view-icon {
  position: relative;
  top: 4px;
  right: 2px;
}

.large-button {
  min-height: 50px;
  .button-icon {
    width: 14px;
    height: 14px;
  }
  .button-text {
    font-size: 17px;
  }
}

// solid-blue
.solid-blue {
  .button-common();
  color: @solid-blue-text;
  background-color: @solid-blue-background;
  border: none;
  .button-icon {
    margin-right: 15px;
    path {
      fill: @solid-blue-text;
    }
  }
  &:hover {
    background-color: @solid-blue-hover;
    color: @solid-blue-text;
  }
  &:active {
    background-color: @solid-blue-press;
  }
  &:disabled {
    .solid-blue-disabled();
  }
  &:focus {
    border: none;
    outline: 0;
  }
}

.solid-blue[disabled] {
  .solid-blue-disabled();
}

// blue-outline

.blue-outline {
  .button-common();
  color: @blue-outline-text;
  background-color: @blue-outline-background;
  border: 1px solid @blue-outline-text;
  .button-icon {
    margin-right: 15px;
    path {
      fill: @blue-outline-text;
    }
  }
  &:hover {
    background-color: @blue-outline-hover;
    color: @blue-outline-text;
  }
  &:active {
    background-color: @blue-outline-press;
  }
  &:disabled {
    .blue-outline-disabled();
  }
  &:focus {
    outline: 0;
  }
}

.blue-outline[disabled] {
  .blue-outline-disabled();
}

// green-outline

.green-outline {
  .button-common();
  color: @green-outline-text;
  background-color: @green-outline-background;
  border: 1px solid @green-outline-text;
  .button-icon {
    margin-right: 15px;
    path {
      fill: @green-outline-text;
    }
  }
  &:hover {
    background-color: @green-outline-hover;
    color: @green-outline-text;
  }
  &:active {
    background-color: @green-outline-press;
  }
  &:disabled {
    .green-outline-disabled();
  }
  &:focus {
    outline: 0;
  }
}

.green-outline[disabled] {
  .green-outline-disabled();
}


// black-outline

.black-outline {
  .button-common();
  color: @black-outline-text;
  background-color: @black-outline-background;
  border: 1px solid @black-outline-text;
  .button-icon {
    margin-right: 15px;
    path {
      fill: @black-outline-text;
    }
  }
  &:hover {
    background-color: @black-outline-hover;
    color: @black-outline-text;
  }
  &:active {
    background-color: @black-outline-press;
  }
  &:disabled {
    .black-outline-disabled();
  }
  &:focus {
    outline: 0;
  }
}

.black-outline[disabled] {
  .black-outline-disabled();
}

// red-outline
.red-outline {
  .button-common();
  color: @red-outline-text;
  background-color: @red-outline-background;
  border: 1px solid @red-outline-text;
  .button-icon {
    margin-right: 15px;
    path {
      fill: @red-outline-text;
    }
  }
  &:hover {
    background-color: @red-outline-hover;
    color: @red-outline-text;
  }
  &:active {
    background-color: @red-outline-press;
  }
  &:disabled {
    .black-outline-disabled();
  }
  &:focus {
    outline: 0;
  }
}

// solid-white
.solid-white {
  .button-common();
  background-color: transparent;
  color: @solid-white-text;
  border: 1px solid @solid-white-background;
  &:hover {
    color: @solid-white-hover;
    background-color: @solid-white-background;
  }
}

// primary-link
.primary-link {
  .button-icon {
    margin-right: 15px;
    path {
      stroke: @blue-outline-text;
      path: @blue-outline-text;
    }
  }
  color: @blue-outline-text;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

// grey-outline
.grey-outline {
  .button-common();
  color: @grey-outline-text;
  background-color: @grey-outline-background;
  border: 1px solid @grey-outline-text;
  .button-icon {
    margin-right: 15px;
    path {
      fill: @grey-outline-text;
    }
  }
  &:hover {
    background-color: @grey-outline-hover;
    color: @grey-outline-text;
  }
  &:active {
    background-color: @grey-outline-press;
  }
  &:disabled {
    .black-outline-disabled();
  }
  &:focus {
    outline: 0;
  }
}
@primary-color: #3E82F1;