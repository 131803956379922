@import "../../styles/variables";
@import "../../styles/mixins";

.ant-layout {
  background: @layout-background;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  min-width: 355px;
  .header-container, .content-container, .footer-container {
    max-width: @layout-width;
    width: 100%;
    margin: 0 auto;
  }
  .ant-layout-header {
    height: 70px;
    background: @header-background;
    box-shadow: 4px 4px 8px @shadow;
  }
  .ant-layout-content {
    .content-container {
      background-color: @content-background;
      min-height: 280px;
      flex: 1;
      padding-top: 32px;
      margin-top: 8px;
      @media (max-width: 1305px) {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }
  .ant-layout-footer {
    background-color: @content-background;
  }
}
@media (max-width: 1315px) {
  .ant-layout {
    .header-container, .content-container, .footer-container {
      width: 100%;
    }
  }
}

@primary-color: #3E82F1;