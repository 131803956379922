.custom-editable-field-wrapper {
  display: flex;
  align-items: center;
  .hoverable {
    margin-left: 20px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.custom-editable-form-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 30px;
  .ant-form-item-control {
    line-height: unset;
  }
  .ant-col {
    margin-bottom: 0 !important;
  }
  .ant-input {
    height: 30px !important;
  }
}


@primary-color: #3E82F1;