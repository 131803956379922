.story-list-modal-content-paragraph {
  display: flex;
}
.status {
  font-weight: bold;
}
.no_plarform {
  margin-right: 6px;
}
.story-list-modal-empty-icon {
  padding-right: 10px;
  padding-left: 1px;
}
