.user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button-common {
  font-weight: bold;
  font-style: normal;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
}
.button-common:disabled {
  cursor: not-allowed;
}
.solid-blue-disabled {
  background-color: #E8EBF8;
  color: #C9C9C9;
}
.solid-blue-disabled path {
  fill: #C9C9C9;
}
.blue-outline-disabled {
  border: 1px solid #C9C9C9;
  background-color: transparent;
  color: #C9C9C9;
}
.blue-outline-disabled path {
  fill: #C9C9C9;
}
.green-outline-disabled {
  border: 1px solid #C9C9C9;
  background-color: transparent;
  color: #C9C9C9;
}
.green-outline-disabled .button-icon path {
  fill: #C9C9C9;
}
.select-arrow {
  width: 17px;
  height: 17px;
  fill: #D2D5DC;
}
.search-input-common:hover {
  outline: none;
  border-color: #d9d9d9;
  background-color: #E9FAFF;
}
.search-input-common:hover input {
  outline: none;
  border-color: #d9d9d9 !important;
  background-color: #E9FAFF;
}
.search-input-common input {
  padding-left: 35px !important;
}
.search-input-common input::placeholder {
  font-weight: 500;
  color: #9CA1AC;
}
.search-input-common input:focus,
.search-input-common input:hover {
  outline: none;
  border-color: #d9d9d9;
  background-color: #E9FAFF;
  box-shadow: none;
}
.search-input-common .ant-input-prefix {
  z-index: 1;
}
.search-input {
  height: 50px;
  box-shadow: 4px 4px 8px rgba(216, 221, 232, 0.8);
}
.search-input:hover {
  outline: none;
  border-color: #d9d9d9;
  background-color: #E9FAFF;
}
.search-input:hover input {
  outline: none;
  border-color: #d9d9d9 !important;
  background-color: #E9FAFF;
}
.search-input .ant-input-prefix svg {
  width: 19px;
  height: 19px;
}
.search-input input {
  padding-left: 35px !important;
}
.search-input input::placeholder {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #9CA1AC;
}
.search-input input:focus,
.search-input input:hover {
  outline: none;
  border-color: #d9d9d9;
  background-color: #E9FAFF;
  box-shadow: none;
}
.search-input .ant-input-prefix {
  z-index: 1;
}
.search-input input[type="text"]::-moz-placeholder {
  line-height: 35px !important;
}
