@import "../../../../styles/variables";

.not-expanded .ant-card-body {
  max-height: 0 !important; // have to set 0 for smooth appearance
}

.title {
  .title-text {
    &:hover {
      text-decoration: underline;
    }
  }
  .arrow-wrapper {
    padding-left: 10px;
    .arrow {
      display: inline-flex;
      align-items: center;
      transform: rotate(90deg);
      transition: all 0.5s;
      svg {
        width: 16px;
        height: 16px;
        &:hover {
          path {
            fill: @menu-item-focused;
          }
        }
      }
    }
    .expanded {
      transform: rotate(270deg);
    }
  }
}
@primary-color: #3E82F1;