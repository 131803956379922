@import "../../styles/variables";
@import "../../styles/mixins";

.ant-layout-header {
  height: 70px;
  border: none !important;
  background: @header-background;
  box-shadow: 4px 4px 8px @shadow;
  padding: 0;
  .logo {
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
  }
  .desktop-menu {
    max-width: 1280px;
    height: 70px;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    @media (max-width: 1305px) {
      padding: 0 7px;
    }
  }
  .mobile-menu {
    display: none;
  }
  @media (max-width: 1000px) {
    .desktop-menu {
      display: none;
    }
    .mobile-menu {
      height: 70px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 7px;
      @media (max-width: 500px) {
        padding: 0 7px;
      }
      .header-user-name {
        display: none;
      }
      .header-dropdown {
        margin-left: unset;
      }
    }
  }
}

.header-image {
  vertical-align: super;
}

.ant-menu {
  line-height: 69px;
  border-bottom: none;
}

.ant-menu-item {
  text-transform: uppercase;
  color: @menu-item;
  font-weight: 600;
  font-size: 13px;
  line-height: 69px;
  letter-spacing: 0.08em;
  &:hover {
    color: @menu-item-focused !important;
  }
}

.ant-menu-item-selected {
  color: @menu-item-focused !important;
}

// search field
.header-search-form {
  padding-left: 40px;
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    padding-left: 5px;
  }
}

.header-search-button {
  outline: none !important;
  background: none;
  border: none;
  cursor: pointer;
  height: 100%;
}

.header-search-input {
  .user-name();
  outline: none !important;
  height: 100%;
  border: none;
  &::placeholder {
    padding-left: 10px;
    .user-name();
    color: @menu-item;
  }
  @media (max-width: 1200px) {
    max-width: 100px;
  }
}

.user-circle {
  display: inline-block;
  padding-left: 12px;
}

.header-dropdown {
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
}

.header-user-name {
  .user-name();
  color: @menu-item-focused;
}

.ant-avatar-string {
  display: flex;
  justify-content: center;
  position: unset;
  left: 0;
  transform: none !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.1em;
}

.ant-dropdown-link {
  cursor: pointer;
}

.header-avatar {
  background-image: linear-gradient(332.32deg, #ff5b8c 0.01%, #edbc0d 83.78%);
  vertical-align: middle;
}

// dropdown overlay (My Profile, Settings, Log Out)

.header-dropdown-overlay {
  box-shadow: 4px 6px 16px @menu-dropdown-shadow;
  border-radius: 6px;
  min-width: unset !important;
  .ant-menu {
    border-radius: 6px;
  }
  .ant-dropdown-menu {
    padding-top: 10px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }
  .ant-dropdown-menu-item {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-transform: none;
    color: @menu-item-focused;
    padding: 9px 20px;
    &:hover {
      text-shadow: 0 0 0.01px black;
      background: @select-hover;
    }
    &:not(:first-child) {
      margin: 0;
    }
  }
}

// mobile menu

.mobile-menu-button {
  border: none;
  display: flex;
  height: 44px;
  position: absolute;
  top: 15px;
  left: 0;
  box-shadow: none;
  padding: 0 15px 0 7px;
  &:after {
    box-shadow: none;
    animation: none;
  }
}

.ant-menu-inline-collapsed {
  transform: translateX(-243px);
}

.ant-menu-inline {
  position: absolute;
  left: 0;
  top: 71px;
  z-index: 2;
  background-color: @header-background;
  height: calc(100vh - 71px);
  width: 243px;
  padding-top: 39px;
  transition: transform 0.3s linear;
  .ant-menu-item {
    font-size: 13px;
    padding-left: 60px !important;
    margin-bottom: 13px !important;
  }
  .header-search-form {
    padding-left: 60px;
    margin-top: 80px;
    .header-search-button {
      height: 19px;
      display: flex;
      padding: 0;
    }
    .header-search-input {
      padding-left: 10px;
    }
  }
}

.ant-menu-inline .ant-menu-item {
  z-index: 3;
}

@primary-color: #3E82F1;