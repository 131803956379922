@import "../../../../styles/variables";
@import "../../../../styles/mixins";

a.container-link {
  &:hover {
    .brand-card-container {
      .brand-preview-and-top-categories {
        .brand-logo-title-container {
          span.brand-title {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.brand-card-container {
  display: block;
  margin-bottom: 20px;
  max-width: 640px;
  background-color: @card-background;
  border-radius: 6px;
  box-shadow: 0 6px 10px @shadow;
  padding: 16px 30px;
  min-height: 360px;
  @media(max-width: 590px) {
    padding: 16px 15px;
  }
  &.container-with-right-space {
    margin-right: 20px;
    @media(max-width: 991px) {
      margin-right: 0;
    }
  }
  .brand-logo-and-title-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .company-name {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: @text;
    }
    .brand-logo {
      min-width: 50px;
      height: 50px;
      border: 1px solid @brand-preview-line;
      border-radius: 8px;
      margin-right: 12px;
      img {
        width: 48px;
        height: 48px;
      }
    }
    .brand-title-and-type-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 15px;
      span.brand-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        a {
          color: @text;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      span.brand-type {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        color: @menu-item;
      }
    }
    .last-update-section {
      margin-left: auto;
      margin-bottom: 30px;
      span {
        font-size: 11px;
        font-weight: 500;
        color: @updated-section;
      }
    }
  }
  .brand-details {
    display: flex;
    flex-direction: row;
    .commissions-details-container {
      padding-left: 10px;
      ul {
        li {
          span.line {
            span.title {
              &.commissions-title {
                width: 112px;
                @media(max-width: 1280px) {
                  width: 75px;
                }
              }
            }
            span.value {
              ul.commissions-totals-list {
                width: 250px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                @media(max-width: 590px) {
                  width: 90px;
                  flex-direction: column;
                }
                li {
                  width: 50%;
                  span.line {
                    span.title {
                      width: 30px;
                      margin-right: 5px;
                    }
                    span.value {
                      min-width: 50px;
                      text-align: left;
                      font-weight: 600;
                      margin-left: unset;
                      @media(max-width: 590px) {
                        margin-right: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .top-categories-and-content-types-container, .commissions-details-container  {
      ul {
        li {
          padding-bottom: 5px;
        }
      }
    }
    .stories-classification-by-statuses {
      ul {
        li {
          padding-bottom: 11px;
        }
      }
    }
    .stories-classification-by-statuses {
      margin-right: 48px;
      @media(max-width: 500px) {
        margin-right: 0;
      }
    }
    .right-block {
      width: 100%;
    }
    .stories-classification-by-statuses, .commissions-details-container {
      ul {
        li {
          span.line {
            span.value {
              &.bold-value {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .stories-classification-by-statuses, .top-categories-and-content-types-container {
      ul {
        li {
          span.line {
            span.value {
              span.title {
                &.semi-bold-title {
                  font-weight: 500;
                }
              }
              min-width: 14px;
              text-align: right;
            }
          }
        }
      }
    }
    .commissions-details-container, .stories-classification-by-statuses,
    .top-categories-and-content-types-container {
      margin-top: 17px;
      ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;
        li {
          &:last-of-type {
            padding-bottom: 0;
          }
          span.line {
            display: flex;
            color: @text;
            span.title {
              font-size: 13px;
              margin-right: 20px;
              display: flex;
              align-items: center;
            }
            span.value {
              font-size: 13px;
            }
          }
        }
      }
    }
    .stories-classification-by-statuses {
      ul {
        li {
          span.line {
            span.title {
              width: 84px;
              .circle {
                min-width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
    .stories-classification-by-statuses, .top-categories-and-content-types-container {
      ul {
        li {
          a {
            color: @text;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .top-categories-and-content-types-container {
      padding: 0 0 12px 10px;
      border-bottom: 1px solid @line;

      ul {
        li {
          span.line {
            span.title {
              min-width: 130px;
            }
          }
        }
      }
    }
  }
  .media-phone({flex-direction: column; max-width: 100%});
  .semi-bold-title {
    font-weight: 500;
  }
  .value {
    margin-left: auto;
    @media(max-width: 590px) {
      margin-left: unset;
    }
  }
  .commissions-value {
    margin-left: 0;
  }
  .stories-classification-by-statuses {
    width: 30%;
    min-width: 117px;
  }
  .right-card-part-wrapper {
    width: 70%;
  }
  .column-title {
    display: flex;
    margin-bottom: 10px;
  }
 .list-item-wrapper {
   margin-bottom: 10px;
   display: flex;
   a {
     word-break: break-word;
     color: @text;
     font-weight: 500;
     font-size: 13px;
     line-height: 16px;
     &:hover {
       text-decoration: underline;
     }
   }
   .amount {
     margin-left: auto;
     font-size: 13px;
     line-height: 16px;
     color: @text;
   }
 }
  .story-status-link {
    flex-shrink: 0;
  }
}

.outer-circle {
  border-radius: 50%;
  background-color: #C5C9DC;
  min-width:10px;
  height:10px;
  position:relative;
  margin-right: 8px;
}

.inner-circle {
  border-radius: 50%;
  top: 20%; left:20%; /* of the container */
  width:60%; /* of the container */
  height:60%; /* of the container */
  position: relative;
}

@primary-color: #3E82F1;