@import "../../styles/variables";
@import "../../styles/mixins";

.table-spinner {
  text-align: center;
  margin-top: 100px;
}

.ant-table-wrapper {
  background: @header-background;
  box-shadow: 4px 4px 8px rgba(216, 221, 232, 0.8);
  border-radius: 6px;
  box-sizing: border-box;
  overflow-x: auto;
  margin-bottom: 10px;
}

.ant-table-column-title {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: @menu-item;
}
.ant-table-thead {
  .story-list-table-th-assignments {
    .ant-table-column-title {
      @media(max-width: 1200px) {
        word-break: break-word;
        min-width: 43px;
      }
    }
  }
  th {
    &:last-child {
      padding-right: 30px;
    }
    &:first-child {
      padding-left: 30px;
    }
    vertical-align: top;
  }
}
.ant-table-thead > tr > th .ant-table-header-column {
  vertical-align: top;
}
.ant-table-column-title {
  vertical-align: top;
}
.link-story .ant-table-row {
  position: relative;
  &:hover {
    .link-btn {
      position: absolute;
      left: 0;
      top: calc(50% - 15px);
      font-weight: 700;
      font-size: 13px;
      color: #3E82F1;
      border: 1px solid #3E82F1;
      border-radius: 6px;
      padding: 7px 12px;
      z-index: 3;
    }
    .link-hover {
      position: absolute;
      display: flex;
      left: 30px;
      background: linear-gradient(90deg, #f0f8ff 65%, rgba(255, 255, 255, 0.2) 100%);
      top: 0;
      height: 100%;
      width: 170px;
      z-index: 2;
    }
    .link-icon {
      margin-right: 8px;
    }
  }
  
}
.ant-table-row {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: @menu-item-focused;
  border-radius: 8px;
  .link-hover {
    display: none;
  }
  cursor: pointer;
  &:hover {
    background: #f3fcff;
    box-shadow: 4px 4px 20px rgba(97, 150, 180, 0.3);
    .table-data-text {
      text-shadow: 0 0 0.01px black;
    }
    .tag-text {
      text-shadow: 0 0 0.01px white;
    }
  }
  td {
    border-bottom: none !important;
    &:last-child {
      text-align: right;
      padding-right: 30px;
    }
    &:first-child {
      padding-left: 30px;
    }
  }
}

.invisible {
  display: none;
}

.brand_platform-td-container, .publish_date-td-container {
  display: flex;
  align-items: center;
}

.linked-stories-td-container {
  display: flex;
  align-items: center;
}


.ant-table-thead > tr > th.ant-table-column-sort {
  background: none;
}

.ant-table-thead > tr > th {
  border-bottom: 1px solid @line;
}

.ant-table-tbody > tr > td {
  @media (max-width: 1200px) {
    padding: 5px;
  }
}

.show-more-burger {
  padding-left: 10px;
  &:hover {
    circle,
    path {
      stroke: @menu-item-focused;
    }
  }
}

// modal

.story-list-modal-container {
  width: 414px !important;
  background: @header-background;
  box-shadow: 4px 6px 16px rgba(160, 166, 178, 0.8);
  border-radius: 6px;
  padding-bottom: 0;
  .ant-modal-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: @text;
    margin: 0 50px;
  }
  .ant-modal-header {
    border-bottom: none;
    padding: 20px 24px 20px 24px;
  }
  .story-list-modal-content-paragraph {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: @text;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .ant-modal-body {
    padding: 0 20px 10px 20px;
  }
  .pop-up-content-linked-stories {
    display: flex;
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.4);
}


// brand icons

.brand-icon {
  img {
    width: 23px;
    height: 23px;
    padding: 0;
  }
}

@primary-color: #3E82F1;