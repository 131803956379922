@import "../../styles/variables";

.log-details {
  margin-top: 20px;
  .ant-card-head {
    padding-top: 30px !important;
  }
  .log-details-row {
    display: flex;
    flex-direction: row;
    margin-top: 11px;
    &:first-child {
      margin-top: 10px;
    }

  }
  .log-details-row-description {
    padding-left: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: @text;
    word-break: break-word;
    flex: 0 1 auto;
  }

  .log-details-row-title {
    flex: 0 0 100px;
    font-size: 13px;
    line-height: 16px;
    color: @text;
  }
}
@primary-color: #3E82F1;