@import "./mixins";

// close circles
.anticon-close-circle, .ant-select-selection__clear {
  border-radius: 6px;
}

.anticon-close-circle svg{
  width: 14px;
  height: 14px;
  background: #E9FAFF;
}

.ant-select-selection__clear {
  right: 15px !important;
}