@import "../../styles/variables";

// contributor
.ant-card.contributor-panel {
  .ant-card-body {
    font-size: 13px;
    color: @text;
    padding-bottom: 30px;
    span {
      font-weight: 500;
      font-size: 15px;
    }
    .bold {
      font-weight: 600;
    }
    .summary-item {
      display: flex;
      .item-name {
        min-width: 125px;
      }
    }
  }
}
.ant-card.special-terms-panel, .ant-card.cost-allocation-panel {
  .ant-card-head {
    .ant-card-head-wrapper {
      flex-direction: row;
      align-items: center;
      .ant-card-head-title {
        margin-right: 20px;
        flex: none;
      }
    }
  }
}
.ant-card.special-terms-panel, .ant-card.cost-allocation-panel, .ant-card.costs-panel {
  .ant-form-item-control {
    input.ant-input {
      height: 50px;
    }
  }
}
.ant-card.commission-details, .ant-card.cost-allocation-panel, .ant-card.costs-panel {
  .ant-select-selection--single {
    height: 50px;
    &:hover {
      background-color: @select-hover;
      border-color: @input-border;
      box-shadow: none;
    }
    &:focus {
      border-color: @input-border;
      box-shadow: none;
    }
  }
}
// special terms
.ant-card.special-terms-panel {
  .ant-card-head {
    .ant-card-head-wrapper {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .creator-email {
    @media(max-width: 768px) {
      margin-top: 30px !important;
    }
  }
  .label-description-wrapper:first-child {
    margin-top: 0;
  }
  .ant-col.ant-form-item-control-wrapper {
    margin-bottom: 0 !important;
  }
  .ant-form-item-label {
    line-height: unset;
    padding-bottom: 8px;
  }
  .automated-story-details-row {
    margin-bottom: 30px;
    &:first-child {
      @media(max-width: 768px) {
        margin-top: 30px;
      }
    }
  }
  .ant-select-selection__rendered {
    margin-right: 15px;
    height: 50px;
    line-height: 50px;
  }
  .ant-select-selection__placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: @menu-item;
  }
}

// documents
.rights-document {
  margin-top: 15px;
  .rights-text-file-name {
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: @blue-outline-text;
    padding-left: 8px;
  }

  svg {
    fill: @blue-outline-text;
  }
}
.commission-details, .cost-allocation-panel {
  .ant-select-selection__rendered {
    margin-right: 15px;
    height: 50px;
    line-height: 50px;
  }
  .ant-select-selection__placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: @menu-item;
  }
}
// commission details
.commission-details {
  .ant-form-item:not(:first-child) {
    margin-top: 30px !important;
  }
  .ant-form-item-no-colon {
    font-size: 13px;
    line-height: 16px;
    color: @menu-item-focused;
  }
  .label-description-wrapper {
    margin-top: 30px;
    .label {
      color: @menu-item-focused;
    }
  }
  .creator-email {
    @media(max-width: 768px) {
      margin-top: 30px !important;
    }
  }
  .label-description-wrapper:first-child {
    margin-top: 0;
  }
  .ant-col.ant-form-item-control-wrapper {
    margin-bottom: 0 !important;
  }
  .ant-form-item-label {
    line-height: unset;
    padding-bottom: 8px;
  }
  .ant-card-body {
    padding-bottom: 20px !important;
  }
  .automated-story-details-row {
    margin-bottom: 30px;
    &:first-child {
      @media(max-width: 768px) {
        margin-top: 30px;
      }
    }
  }
  .ant-select-selection--single {
    &:hover {
      background-color: @select-hover;
      border-color: @input-border;
      box-shadow: none;
    }
    &:focus {
      border-color: @input-border;
      box-shadow: none;
    }
  }
}
.special-terms-panel, .costs-panel, .cost-allocation-panel {
  input.ant-input, .cost-input {
    &:hover {
      background-color: @select-hover;
      border-color: @input-border;
      box-shadow: none;
    }
    &:focus {
      border-color: @input-border;
      box-shadow: none;
    }
  }
}
// costs
.costs-panel {
  .costs-description-col textarea {
    margin-bottom: 0;
    height: 50px;
    &:hover {
      background-color: @select-hover;
    }
  }
  .costs-description-row {
    margin-bottom: 30px;
  }
  label, span.label {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: @menu-item-focused;
  }
  .cost-input {
    height: 50px;
  }
  .cost-form-row {
    margin-top: 10px;
  }
  .cost-form-delete-row {
    padding-bottom: 25px;
    border-bottom: 1px solid @line;
  }
  .description {
    margin-top: 24px;
  }
  .delete-col {
    i {
      float: right;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .cost-small-field {
    @media(max-width: 768px) {
      width: 25%;
      display: inline-flex;
    }
  }
  .costs-title-currency-total {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: @placeholder;
    padding-left: 20px;
  }
}

// cost allocation
.cost-allocation-row {
  .ant-form-item-label {
    padding-bottom: 8px;
  }
  .invoice-date-col {
    .ant-form-item-control-wrapper {
      min-width: 150px;
    }
  }
}
.cost-allocation-panel {
  .cost-allocation-form {
    padding: 25px 0;
    border-bottom: 1px solid @line;
    &:first-of-type {
      padding-top: 0;
    }
    .level-col, .percentage-col {
      height: 100px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-label {
      padding-bottom: 8px;
    }
    .ant-form-item-control {
      line-height: 30px;
    }
    .amount-and-cost-allocation-delete-container {
      i.anticon {
        width: 100%;
        text-align: right;
        font-size: 24px;
        margin-top: 20px;
      }
    }
    button.green-outline {
      &.hidden {
        display: none;
      }
      &.visible {
        display: block;
      }
    }
  }
}

// custom validation
.custom-validation-failed {
  border: 1px solid @validation;
}
.validation-text-explanation {
  color: #f5222d;
  margin-top: 5px;
}

.bottom-info {
  margin-top: 24px;
}

@primary-color: #3E82F1;