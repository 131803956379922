.reset-password-page-wrapper {
  .reset-password-page-container {
    .reset-password-form {
      .ant-form-item {
        margin: 0 0 40px 0;
      }
    }
  }
}

@primary-color: #3E82F1;