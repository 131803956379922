@import "../../styles/variables";
@import "../../styles/mixins";

.confirm-reset-password-page-wrapper {
  .confirm-reset-password-page-container {
    .confirm-reset-password-form {
      .ant-form-item {
        margin: 0 0 25px 0;
        .ant-form-explain {
          .media-phone({max-width: 250px});
        }
        &:last-of-type {
          margin-bottom: 40px;
        }
      }
    }
  }
}

@primary-color: #3E82F1;