@import "./variables";

.back-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: @menu-item-focused;
  margin-bottom: 20px;
  .anticon {
    margin-right: 5px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  &:hover {
    text-decoration: underline;
    color: @menu-item-focused;
  }
}